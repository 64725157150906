import React, { useEffect, useState } from "react";

import type {
  ApolloClient,
  DocumentNode,
  NormalizedCacheObject,
} from "@apollo/client";
import { Button, Modal, ErrorMessage, Callout } from "@commonComponents";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";

import { gridSpacing } from "@utils";
import { downloadFile } from "@utils";

import { setExportForTable } from "~/src/actions/main";
import store from "~/src/store";
import type { UserAPI } from "~/src/types";

export const CSV_DOWNLOAD_MAXIMUM_ROWS = 10_000;
const CSV_DOWNLOAD_MAXIMUM_ROWS_FORMATTED = Intl.NumberFormat("en-US").format(
  CSV_DOWNLOAD_MAXIMUM_ROWS,
);

export type ButtonStatus = "ready" | "retrieving";

interface QueryParams {
  [key: string]: unknown; // General type for additional query params
  page_size: number;
  page: number;
  ordering?: string | number; // Optional ordering key
}

interface Sort {
  id?: number | string;
  desc?: boolean;
}

export interface TableExportData {
  pageSize?: number;
  currentPage?: number;
  sort?: Sort;
  queryParams?: Record<string, unknown>; // Assuming you might have this from previous context
}

export interface ColumnMapItem {
  csvColumn: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsColumn: string | (() => unknown) | ((row: Record<string, any>) => void);
}

export interface SetExportProps {
  data?: Record<string, unknown>[];
  url?: string;
  exportName: string;
  tableData?: TableExportData;
  columnMap: ColumnMapItem[];
  graphqlData?: {
    query: DocumentNode;
    variables: Record<string, unknown>;
  };
}

interface EmptyStateType {
  isOpened: boolean;
  data: Record<string, unknown>[];
  url: string;
  exportName: string;
  tableData: TableExportData;
  columnMap: ColumnMapItem[];
  graphqlData?: {
    query: DocumentNode;
    variables: Record<string, unknown>;
  };
}

interface TableExportProps extends EmptyStateType {
  setExportForTable: (props: EmptyStateType) => void;
  user: UserAPI;
  graphqlClient: ApolloClient<NormalizedCacheObject>;
}

const emptyState: EmptyStateType = {
  isOpened: false,
  data: [],
  url: "",
  exportName: "",
  tableData: {}, // Adjusted to include queryParams if needed
  columnMap: [],
  graphqlData: undefined,
};

export const setTableExport = (props: SetExportProps = emptyState) => {
  const { data, url, exportName, tableData, columnMap, graphqlData } = props;
  store.dispatch(
    setExportForTable({
      isOpened: true,
      data,
      url,
      exportName,
      tableData,
      columnMap,
      graphqlData,
    }),
  );
};

const TableExport: React.FC<TableExportProps> = ({
  isOpened,
  data,
  url,
  tableData,
  columnMap,
  exportName,
  graphqlData,
  setExportForTable,
  user,
  graphqlClient,
}) => {
  const [exportData, setExportData] = useState<Record<string, unknown>[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [exportStatus, setExportStatus] = useState<ButtonStatus>("ready");

  const fetchData = async () => {
    const query: QueryParams = {
      ...tableData.queryParams,
      page_size: CSV_DOWNLOAD_MAXIMUM_ROWS,
      page: 1,
    };

    // Add ordering to the query based on tableData.sort
    if (tableData.sort) {
      query.ordering = tableData.sort.desc
        ? `-${tableData.sort.id}`
        : tableData.sort.id;
    }

    if (graphqlData) {
      try {
        const { data } = await graphqlClient.query({
          query: graphqlData.query,
          variables: graphqlData.variables ?? {},
        });

        const key = Object.keys(data)[0];
        const results = data[key].results;

        setExportData(results);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Something went wrong fetching export data");
      }

      setLoading(false);
      return;
    }

    axios
      .get(url, {
        params: query,
      })
      .then((res) => {
        setExportData(
          res.data.result?.results ? res.data.result.results : res.data.result,
        );
      })
      .catch((err) => {
        setError("Something went wrong fetching export data");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (isOpened) {
      if (url || graphqlData) {
        fetchData();
        setExportData([]);
      } else {
        setExportData(data);
        setLoading(false);
      }
    }
  }, [url, data, isOpened]);

  const exportAsCSV = () => {
    setExportStatus("retrieving");
    const exportTime = moment().format("MM-DD-YYYY HH:mm:ss");

    // Implementation that handles all cases
    function getValue(
      obj: Record<string, unknown>,
      pathOrFunc: ColumnMapItem["jsColumn"],
    ): unknown {
      if (typeof pathOrFunc === "function") {
        // Attempt to differentiate between the function types at runtime
        if (pathOrFunc.length === 0) {
          // Explicitly cast and then call the no-argument function type
          const noArgFunc = pathOrFunc as () => unknown;
          return noArgFunc();
        } else {
          // Since TypeScript expects the one-argument version here, no need for further casting
          return pathOrFunc(obj);
        }
      }

      if (Object.hasOwn(obj, pathOrFunc)) return obj[pathOrFunc];

      const parts = pathOrFunc.split(".");
      let current: unknown = obj;
      let isPathValid = true;

      parts.forEach((part) => {
        if (
          isPathValid &&
          !!current &&
          typeof current === "object" &&
          part in current
        ) {
          current = (current as Record<string, unknown>)[part];
        } else {
          isPathValid = false;
        }
      });

      return isPathValid ? current : undefined;
    }

    const adjustedColumnMap = [
      ...columnMap,
      { csvColumn: "export_time", jsColumn: () => exportTime },
      { csvColumn: "exported_by", jsColumn: () => user.email },
    ];

    const csvData = exportData.map((row) =>
      adjustedColumnMap
        .map((col) => {
          let value = getValue(row, col.jsColumn);
          if (Array.isArray(value)) {
            value = `"${value.join(", ")}"`;
          } else if (typeof value === "string" && value.includes(",")) {
            value = `"${value}"`;
          }
          return value ?? "";
        })
        .join(","),
    );

    csvData.unshift(adjustedColumnMap.map((col) => col.csvColumn).join(","));

    const csvString = csvData.join("\n").replace(/\\\\/g, "\\");
    const sanitizedAppName = exportName.replaceAll(" ", "");

    const fileName = `${sanitizedAppName}_${moment().format("MM-DD-YYYY")}.csv`;

    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvString);
    downloadFile(csvContent, fileName);

    setExportStatus("ready");
  };

  const renderModalContents = () => (
    <>
      {`${exportName} will be exported to a .csv file with ${Intl.NumberFormat(
        "en-US",
      ).format(exportData?.length)} records and ${
        columnMap.length + 2
      } columns.`}

      {exportData?.length >= CSV_DOWNLOAD_MAXIMUM_ROWS && (
        <Callout
          mt={2}
          type="warning"
          text={`Table export has a limit of ${CSV_DOWNLOAD_MAXIMUM_ROWS_FORMATTED} records.
                  The current selection contains more than ${CSV_DOWNLOAD_MAXIMUM_ROWS_FORMATTED} records. 
                  Please note that only the first ${CSV_DOWNLOAD_MAXIMUM_ROWS_FORMATTED} records will be exported.`}
        />
      )}

      {error && (
        <ErrorMessage style={{ marginTop: gridSpacing[3] }}>
          {error}
        </ErrorMessage>
      )}
    </>
  );

  return (
    <Modal
      isOpened={isOpened}
      title="Export Table"
      toggle={() => setExportForTable(emptyState)}
      focusTrap={false}
      loadingText="Preparing table for export. This may take a few moments."
      isLoading={loading}
    >
      {renderModalContents()}
      <Modal.Footer>
        <Button
          mr
          type="secondary"
          text="Cancel"
          onClick={() => {
            setExportForTable(emptyState);
          }}
        />
        <Button
          text="Export"
          onClick={() => {
            exportAsCSV();
            setExportForTable(emptyState);
          }}
          status={exportStatus}
          affirmationText="Exporting"
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: {
  main: {
    user: UserAPI;
    tableExport: {
      isOpened: boolean;
      data: Record<string, unknown>[];
      url: string;
      exportName: string;
      tableData: TableExportData;
      columnMap: ColumnMapItem[];
      graphqlData?: {
        query: DocumentNode;
        variables: Record<string, unknown>;
      };
    };
  };
}) => {
  return {
    isOpened: state?.main?.tableExport?.isOpened || emptyState.isOpened,
    data: state?.main?.tableExport?.data || emptyState.data,
    tableData: state?.main?.tableExport?.tableData || emptyState.tableData,
    url: state?.main?.tableExport?.url || emptyState.url,
    columnMap: state?.main?.tableExport?.columnMap || emptyState.columnMap,
    exportName: state?.main?.tableExport?.exportName || emptyState.exportName,
    graphqlData: state?.main?.tableExport?.graphqlData,
    user: state?.main?.user,
  };
};
export default connect(mapStateToProps, { setExportForTable })(TableExport);
