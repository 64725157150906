import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import { DropDown } from "../";

const StyledTableActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  i {
    padding: 0 8px;
    color: ${colorTheme("neutral")};

    &:hover {
      color: ${colorTheme("neutralL1")};
      cursor: pointer;
    }
  }
`;

interface TableActionsProps {
  direction: "left" | "right" | "up" | "down";
  items: {
    title: string;
    visible?: boolean;
    onClick: () => void;
    icon: string;
  }[];
  testId?: string;
}

const TableActions = ({ direction, items, testId }: TableActionsProps) => (
  <StyledTableActions>
    <DropDown direction={direction}>
      <DropDown.Toggle>
        <i
          className="fa-solid fa-ellipsis"
          data-testid={testId && `${testId}-menu-toggle`}
        />
      </DropDown.Toggle>
      <DropDown.Menu>
        {items.length > 0 ? (
          items.map((item) => (
            <DropDown.Item
              key={item.title}
              visible={item.visible}
              onClick={item.onClick}
              testId={testId && `${testId}-menu-item-${item.title}`}
            >
              <i className={`fa-solid ${item.icon}`} style={{ width: 25 }} />
              {item.title}
            </DropDown.Item>
          ))
        ) : (
          <div style={{ padding: 10 }}>No actions available</div>
        )}
      </DropDown.Menu>
    </DropDown>
  </StyledTableActions>
);

export default TableActions;
