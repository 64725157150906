import type { ReactNode, CSSProperties } from "react";
import React from "react";

import * as HoverCard from "@radix-ui/react-hover-card";
import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../../utils/gridSpacing";

const ContentContainer = styled.div`
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.2);
  border-radius: ${gridSpacing[1]}px;
  background-color: ${colorTheme("white")};
`;

// TRIGGER
interface TriggerProps {
  children?: ReactNode;
  asChild?: boolean;
  style?: CSSProperties;
}

const Trigger = ({ children, asChild = false, style = {} }: TriggerProps) => (
  <HoverCard.Trigger asChild={asChild} style={{ ...style }}>
    {children}
  </HoverCard.Trigger>
);

// CONTENT
interface ContentProps {
  children?: ReactNode;
  arrowStyle?: object;
  sideOffset?: number;
  align?: "start" | "center" | "end";
  side?: "top" | "right" | "bottom" | "left";
  alignOffset?: number;
  arrowPadding?: number;
  includeArrow?: boolean;
  zIndex?: number;
  style?: CSSProperties;
}
const Content = ({
  children,
  arrowPadding,
  includeArrow = false,
  arrowStyle,
  sideOffset,
  align,
  side,
  alignOffset,
  zIndex,
  style = {},
}: ContentProps) => (
  <HoverCard.Portal>
    <HoverCard.Content
      sideOffset={sideOffset}
      align={align}
      side={side}
      alignOffset={alignOffset}
      arrowPadding={arrowPadding}
      style={{ zIndex: zIndex ?? 1000, ...style }}
    >
      <ContentContainer>{children}</ContentContainer>
      {includeArrow && (
        <HoverCard.Arrow style={{ width: 20, height: 10, ...arrowStyle }} />
      )}
    </HoverCard.Content>
  </HoverCard.Portal>
);

// ROOT
interface RootProps {
  children?: ReactNode;
  openDelay?: number;
  closeDelay?: number;
}

const Root = ({ children, openDelay = 700, closeDelay = 300 }: RootProps) => (
  <HoverCard.Root openDelay={openDelay} closeDelay={closeDelay}>
    {children}
  </HoverCard.Root>
);

Root.Trigger = Trigger;
Root.Content = Content;

export default Root;
