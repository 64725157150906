import type { ReactNode } from "react";
import React from "react";

import styled from "styled-components";

import { Button, Card, BreadCrumbs, Loading, GraphicCallout } from "../";
import gridSpacing from "../../../utils/gridSpacing";
import type { Route } from "../BreadCrumbs/BreadCrumbs";
import type { ButtonStatus, ButtonType } from "../Button/Button";

const Header = styled.div<{ $hasBreadCrumbs: boolean }>`
  padding: ${({ $hasBreadCrumbs }) =>
      $hasBreadCrumbs ? gridSpacing[2] : gridSpacing[7]}px
    0 ${gridSpacing[6]}px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CardInner = styled.div<{ $padding: number }>`
  padding: ${({ $padding }) => $padding}px;
`;

const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface PanelProps {
  header?: string | ReactNode;
  headerStyle?: React.CSSProperties;
  children?: ReactNode;
  buttonText?: string;
  buttonIcon?: string;
  buttonFunc?: () => void;
  buttonType?: ButtonType;
  buttonDisabled?: boolean;
  buttonStatus?: ButtonStatus;
  buttonTestId?: string;
  buttonAffirmationText?: string;
  secondButtonText?: string;
  secondButtonIcon?: string;
  secondButtonFunc?: () => void;
  secondButtonType?: ButtonType;
  secondButtonDisabled?: boolean;
  secondButtonStatus?: ButtonStatus;
  secondButtonAffirmationText?: string;
  secondButtonTestId?: string;
  buttonsContainer?: ReactNode;
  padding?: number;
  style?: React.CSSProperties;
  outerStyle?: React.CSSProperties;
  backFunc?: () => void;
  breadCrumbs?: Array<Route>;
  isLoading?: boolean;
  errorMessage?: string;
}

function Panel({
  header,
  headerStyle,
  children,
  buttonText,
  buttonIcon,
  buttonFunc,
  buttonType,
  buttonDisabled = false,
  buttonStatus,
  buttonTestId,
  buttonAffirmationText,
  secondButtonText,
  secondButtonIcon,
  secondButtonFunc,
  secondButtonType,
  secondButtonDisabled,
  secondButtonStatus,
  secondButtonAffirmationText,
  secondButtonTestId,
  buttonsContainer,
  padding = 0,
  style,
  outerStyle,
  backFunc,
  breadCrumbs, // TODO: This needs to be upgrade to breadcrumbs v2
  isLoading = false,
  errorMessage,
}: PanelProps) {
  const renderContent = () => {
    if (errorMessage) {
      return <GraphicCallout error="issue" subheader={errorMessage} />;
    } else if (isLoading) {
      return <Loading center />;
    } else {
      return children;
    }
  };

  return (
    <>
      {breadCrumbs && (
        <BreadCrumbs
          routes={breadCrumbs}
          style={{ paddingTop: gridSpacing[7] }}
        />
      )}
      {header && (
        <Header $hasBreadCrumbs={!!breadCrumbs} style={headerStyle}>
          {typeof header === "string" ? <h2>{header}</h2> : header}
          {!isLoading && !errorMessage && (
            <ButtonContainer>
              {buttonFunc && (
                <Button
                  text={buttonText}
                  icon={buttonIcon}
                  onClick={buttonFunc}
                  disabled={buttonDisabled}
                  type={buttonType}
                  status={buttonStatus}
                  affirmationText={buttonAffirmationText}
                  testId={buttonTestId}
                />
              )}
              {secondButtonFunc && (
                <Button
                  style={{ marginLeft: gridSpacing[4] }}
                  text={secondButtonText}
                  icon={secondButtonIcon}
                  onClick={secondButtonFunc}
                  disabled={secondButtonDisabled}
                  type={secondButtonType}
                  status={secondButtonStatus}
                  affirmationText={secondButtonAffirmationText}
                  testId={secondButtonTestId}
                />
              )}
              {buttonsContainer}
            </ButtonContainer>
          )}
        </Header>
      )}
      <Card style={{ boxShadow: "none", ...outerStyle }}>
        <CardInner style={style} $padding={padding}>
          {renderContent()}
          <BackContainer>
            {backFunc && (
              <Button type="secondary" onClick={backFunc} text="Back" />
            )}
          </BackContainer>
        </CardInner>
      </Card>
    </>
  );
}

export default Panel;
