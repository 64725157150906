import React, { useState, useEffect, useContext, useRef } from "react";

import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { colorTheme } from "@utils";

import { DropDownContext, DropDownProvider } from "./Context";

const DropDownContainer = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
`;

const DropDownMenu = styled.div`
  position: absolute;
  top: 100%;
  z-index: 109;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: ${colorTheme("neutral")};
  text-align: left;
  list-style: none;
  background-color: ${colorTheme("white")};
  background-clip: padding-box;
  border: 1px solid ${colorTheme("neutralL4")};
  border-radius: 0.25rem;
  outline: none !important;
  overflow: auto;
  max-height: 425px;
  flex-direction: column;
  display: ${(props) => (props.show ? "flex" : "none")};
  margin-top: ${(props) => (props.down ? props.offset : 0)}px;
`;

const DropDownMenuLeft = styled(DropDownMenu)`
  top: 0;
  left: auto;
  right: 100%;
  margin-top: 0;
  margin-right: ${(props) => props.offset}px;
`;

const DropDownItem = styled.div`
  display: block;
  padding: 0.45rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: ${colorTheme("neutralL1")};
  text-align: inherit;
  white-space: nowrap;
  background-color: ${colorTheme("transparent")};
  border: 0;

  &:hover {
    background: ${colorTheme("neutralL4")};
    cursor: pointer;
  }
`;

const ToggleContainer = styled.div`
  white-space: no-wrap;
  outline: 0 !important;
`;

const DropDown = ({
  children,
  direction = "down",
  closeOnSelect = true,
  style,
  openCallback,
  closeCallback,
}) => {
  const [state, setState] = useState({
    toggle: false,
    direction,
    closeOnSelect,
    openCallback,
    closeCallback,
  });
  const parentRef = useRef();

  const setValue = (key, value) => {
    let newState = { ...state };
    newState[key] = value;
    setState(newState);
  };

  let values = {
    ...state,
    parentRef,
    setValue: (key, value) => setValue(key, value),
  };

  return (
    <DropDownProvider values={values}>
      <DropDownContainer ref={parentRef} style={style}>
        {children}
      </DropDownContainer>
    </DropDownProvider>
  );
};

const Item = ({ children, onClick, visible = true, testId }) => {
  if (!visible) return null;
  const context = useContext(DropDownContext);

  return (
    <DropDownItem
      data-testid={testId}
      onClick={() => {
        onClick();
        if (context.closeOnSelect) {
          if (context.closeCallback) context.closeCallback();
          context.setValue("toggle", false);
        }
      }}
    >
      {children}
    </DropDownItem>
  );
};

const Toggle = ({ children, testId }) => {
  const context = useContext(DropDownContext);

  return (
    <ToggleContainer
      onClick={(e) => {
        // If you do not want a child to open the menu add attribute "data-disabletoggle=true" to it
        if (e.target.dataset.disabletoggle) return;
        if (context.openCallback) context.openCallback();
        context.setValue("toggle", !context.toggle);
      }}
      data-testid={testId}
    >
      {children}
    </ToggleContainer>
  );
};

const Menu = ({ children }) => {
  const context = useContext(DropDownContext);
  const menuRef = useRef(null);

  let _Menu = null;
  let _left = null;
  let _right = null;
  let top = null;

  const onBlur = async (e) => {
    e.persist();
    await new Promise((resolve) => setTimeout(resolve, 1)); // Need to wait 1ms for activeElement to change

    // If the active element is the search input or dropdown menu do not close the menu
    if (
      document.activeElement.id !== "search" &&
      document.activeElement.id !== "dropDown"
    ) {
      if (context.closeCallback) context.closeCallback();
      context.setValue("toggle", false);
    }
  };

  useEffect(() => {
    if (context.toggle) {
      menuRef.current.focus();
    }
  }, [context.toggle]);

  if (context.parentRef.current) {
    let parentDiv = context.parentRef.current.getBoundingClientRect();
    if (context.direction === "left") {
      _right = window.innerWidth - parentDiv.left;
      top = parentDiv.top + window.scrollY;
    } else {
      _left = parentDiv.left;
      top = parentDiv.top + parentDiv.height + window.scrollY;
    }

    //  If going off dropdown screen put it back on screen
    if (window.innerWidth <= parentDiv.right + parentDiv.width) {
      _left = _left + (parentDiv.right - window.innerWidth);
    } else if (_left < 0) {
      _left = 5;
    }
  }

  switch (context.direction) {
    // case "up":
    //   _Menu = (
    //     <DropDownMenuUp show={context.toggle} ref={menuRef}>
    //       {children}
    //     </DropDownMenuUp>
    //   );
    //   break;
    case "left":
      _Menu = (
        <DropDownMenuLeft
          show={context.toggle}
          ref={menuRef}
          style={{ right: _right, top: top }}
          onBlur={onBlur}
          tabIndex={0}
          id="dropDown"
        >
          {children}
        </DropDownMenuLeft>
      );
      break;
    // case "right":
    //   _Menu = (
    //     <DropDownMenuRight show={context.toggle} ref={menuRef}>
    //       {children}
    //     </DropDownMenuRight>
    //   );
    //   break;
    default:
      _Menu = (
        <DropDownMenu
          down
          show={context.toggle}
          ref={menuRef}
          style={{ left: _left, top: top }}
          onBlur={onBlur}
          tabIndex={0}
          id="dropDown"
        >
          {children}
        </DropDownMenu>
      );
  }

  if (!context.toggle) return null;

  return ReactDOM.createPortal(_Menu, document.querySelector("body"));
};

DropDown.propTypes = {
  children: PropTypes.any,
  /** Direction of dropdown enum['up', 'down', 'left', 'right'] */
  direction: PropTypes.string,
  /** If a user selects an element if the menu should close or not */
  closeOnSelect: PropTypes.bool,
  /** Style object that wraps the whole dropdown div  */
  style: PropTypes.object,
  openCallback: PropTypes.func,
  closeCallback: PropTypes.func,
};

Item.propTypes = {
  children: PropTypes.any,
  /** OnClick callback function */
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  testId: PropTypes.string,
};

Menu.propTypes = {
  children: PropTypes.any,
};

Toggle.propTypes = {
  children: PropTypes.any,
  /** data-testid attribute */
  testId: PropTypes.string,
};

DropDown.Menu = Menu;
DropDown.Toggle = Toggle;
DropDown.Item = Item;

export default DropDown;
