import React, { createContext } from "react";

import PropTypes from "prop-types";

const DropDownContext = createContext();

const DropDownProvider = ({ children, values }) => (
  <DropDownContext.Provider value={values}>{children}</DropDownContext.Provider>
);

DropDownProvider.propTypes = {
  children: PropTypes.any,
  values: PropTypes.object,
};

export { DropDownProvider, DropDownContext };
