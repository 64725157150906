import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../../utils/gridSpacing";
import ToolTip from "../ToolTip/ToolTip";
import { getButtonClassName } from "../utilities";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

export type ButtonType =
  | "primary"
  | "secondary"
  | "toggle"
  | "link"
  | "neutralLink"
  | "custom";

export type ButtonStatus = "ready" | "retrieving";

interface ButtonProps extends MarginInterface {
  type?: ButtonType;
  icon?: string;
  text?: string;
  affirmationText?: string;
  disabled?: boolean;
  style?: object;
  iconPos?: "left" | "right";
  iconStyle?: object;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  customColors?: {
    color?: string;
    background?: string;
    border?: string;
    hoverColor?: string;
    hoverBackground?: string;
    activeBackground?: string;
    activeColor?: string;
  };
  status?: ButtonStatus;
  small?: boolean;
  danger?: boolean;
  testId?: string;
  toggled?: boolean;
  count?: number;
  value?: string | number | boolean;
  htmlType?: "button" | "submit" | "reset";
  tooltip?: string;
  tooltipDirection?: "top" | "bottom" | "left" | "right";
}

interface ButtonStyleProps {
  $buttonType?:
    | "primary"
    | "secondary"
    | "toggle"
    | "link"
    | "neutralLink"
    | "custom";
  $colors: {
    color?: string;
    background?: string;
    border?: string;
    hoverColor?: string;
    hoverBackground?: string;
    activeBackground?: string;
    activeColor?: string;
  };
  disabled?: boolean;
  value?: string | number | boolean;
}

const smallStyle: object = {
  height: 32,
  fontSize: 14,
  paddingLeft: 12,
  paddingRight: 12,
};

const ButtonStyle = styled.button<ButtonStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ $colors }) => $colors.color};
  background: ${({ $colors }) => $colors.background};
  border: 1px solid ${({ $colors }) => $colors.border};
  border-radius: 8px;
  height: 40px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 16px;
  line-height: 18px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  span {
    white-space: nowrap;
  }

  &:hover {
    color: ${({ $colors }) => $colors.hoverColor || $colors.color};
    background: ${({ $colors }) => $colors.hoverBackground};
    span {
      text-decoration: ${({ $buttonType }) =>
        $buttonType === "link" || $buttonType === "neutralLink"
          ? "underline"
          : "none"};
    }
  }

  &:active {
    background: ${({ $colors }) => $colors.activeBackground};
    color: ${({ $colors }) => $colors.activeColor};
  }
`;

const Icon = styled.i<{ $left?: boolean; $right?: boolean }>`
  margin-left: ${({ $right }) => ($right ? "8px" : "0")};
  margin-right: ${({ $left }) => ($left ? "8px" : "0")};
`;

const CountIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${colorTheme("white")};
  background: ${colorTheme("primaryL2")};
  margin-left: ${gridSpacing[2]}px;
`;

const SpinningIcon: React.FC<{ $left?: boolean }> = ({ $left }) => (
  <>
    <i className="fa-regular fa-circle-notch fa-spin" />
    <span style={{ marginRight: $left ? 8 : 0 }} />
  </>
);

const getColors = (
  type: ButtonProps["type"],
  danger: boolean,
  toggled: boolean,
): ButtonStyleProps["$colors"] => {
  if (type === "secondary") {
    return {
      background: colorTheme("white"),
      border: danger ? colorTheme("danger") : colorTheme("neutralL3"),
      color: danger ? colorTheme("danger") : colorTheme("neutral"),
      hoverColor: danger ? colorTheme("danger") : colorTheme("neutral"),
      hoverBackground: danger ? colorTheme("dangerL5") : "#f2f2f2",
      activeBackground: danger
        ? colorTheme("dangerL5")
        : colorTheme("neutralL4"),
    };
  } else if (type === "toggle") {
    let toggleColors = {
      background: toggled ? colorTheme("infoL2") : colorTheme("white"),
      border: colorTheme("neutralL3"),
      color: toggled ? colorTheme("info") : colorTheme("neutralL2"),
      hoverColor: toggled ? colorTheme("info") : colorTheme("neutral"),
      hoverBackground: toggled ? colorTheme("infoL1") : colorTheme("neutralL5"),
      activeBackground: toggled ? colorTheme("infoL1") : colorTheme("infoL1"),
      activeColor: colorTheme("info"),
    };

    if (danger) {
      toggleColors = {
        background: toggled ? colorTheme("dangerL3") : colorTheme("white"),
        border: colorTheme("neutralL3"),
        color: toggled ? colorTheme("dangerD2") : colorTheme("danger"),
        hoverColor: toggled ? colorTheme("dangerD2") : colorTheme("danger"),
        hoverBackground: toggled
          ? colorTheme("dangerL2")
          : colorTheme("dangerL5"),
        activeBackground: toggled
          ? colorTheme("dangerL2")
          : colorTheme("dangerL5"),
        activeColor: colorTheme("dangerD2"),
      };
    }

    return toggleColors;
  } else if (type === "link" || type === "neutralLink") {
    const colors: ButtonStyleProps["$colors"] = {
      background: "transparent",
      border: "transparent",
      hoverBackground: "transparent",
      activeBackground: "transparent",
    };

    if (type === "link") {
      colors.color = danger ? colorTheme("danger") : colorTheme("primary");
      colors.hoverColor = danger
        ? colorTheme("dangerD2")
        : colorTheme("primaryD1");
    } else {
      if (danger) {
        console.error("Neutral link button does not support danger");
      }

      colors.color = colorTheme("neutral");
      colors.hoverColor = colorTheme("black");
    }

    return colors;
  }

  // Primary
  if (danger) {
    return {
      background: colorTheme("danger"),
      color: colorTheme("white"),
      hoverBackground: colorTheme("dangerD2"),
      activeBackground: "#681219",
    };
  }

  return {
    background: colorTheme("primary"),
    border: colorTheme("primary"),
    color: colorTheme("white"),
    hoverBackground: "#043D6B",
    activeBackground: colorTheme("primaryD1"),
  };
};

/**
 * Button component
 * @param type - Button type
 * @param icon - Icon to display
 * @param text - Text to display
 * @param affirmationText - Text to display when status is retrieving
 * @param disabled - Whether button is disabled
 * @param style - Custom style
 * @param iconPos - Icon position
 * @param iconStyle - Custom icon style
 * @param onClick - On click handler
 * @param customColors - Custom colors
 * @param status - Button status
 * @param small - Whether button is small
 * @param danger - Whether button is danger
 * @param testId - Test id
 * @param toggled - Whether button is toggled
 * @param count - Count to display
 * @param htmlType - The button type attribute
 * @param value - Value used for buttons in a group
 * @param tooltip - Tooltip text
 * @param tooltipDirection - Tooltip direction
 * @param m - Margin
 * @param mx - Margin x
 * @param my - Margin y
 * @param mt - Margin top
 * @param mb - Margin bottom
 * @param mr - Margin right
 * @param ml - Margin left
 * @returns Button component
 * @example
 * <Button
 * type="primary"
 * icon="fa-solid fa-plus"
 * text="Add"
 * affirmationText="Adding..."
 * onClick={() => console.log("clicked")}
 * />
 */
const Button: React.FC<ButtonProps> = ({
  type = "primary",
  icon,
  text = "",
  affirmationText = "",
  disabled,
  style,
  iconPos = "left",
  iconStyle,
  onClick,
  customColors = {},
  status = "ready",
  small,
  danger = false,
  testId,
  toggled = false,
  count,
  htmlType = "button",
  value,
  tooltip,
  tooltipDirection,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}) => {
  const [buttonText, setButtonText] = useState(text);

  useEffect(() => {
    if (status === "retrieving" && buttonText !== affirmationText) {
      setButtonText(affirmationText);
    } else if (status === "ready" && buttonText !== text) {
      setButtonText(text);
    }
  }, [status, text]);

  const colors = getColors(type, danger, toggled);

  if (type === "custom") {
    colors.background = customColors.background || "transparent";
    colors.border = customColors.border || "transparent";
    colors.color = customColors.color || colorTheme("neutral");
    colors.background = customColors.background || "transparent";
    colors.hoverBackground =
      customColors.hoverBackground || customColors.background;
    colors.hoverColor =
      customColors.hoverColor || customColors.color || colorTheme("neutral");
    colors.activeBackground =
      customColors.activeBackground || customColors.background || "transparent";
  }

  if (disabled) {
    colors.background = colorTheme("neutralL4");
    colors.color = colorTheme("neutralL2");
    colors.border = colorTheme("neutralL4");
    colors.hoverColor = colorTheme("neutralL2");
    colors.hoverBackground = colorTheme("neutralL3");
    colors.activeBackground = colorTheme("neutralL3");

    if (type === "secondary" || type === "toggle") {
      colors.border = colorTheme("neutralL3");
    } else if (type === "link" || type === "neutralLink") {
      colors.hoverColor = colorTheme("neutralL1");
      colors.background = "transparent";
      colors.border = "transparent";
      colors.hoverBackground = "transparent";
      colors.activeBackground = "transparent";
    }
  }

  let buttonStyle: React.CSSProperties = {};

  if (!text) {
    buttonStyle = { paddingLeft: 12, paddingRight: 12 };
  }

  if (small) {
    if (!text) {
      buttonStyle = { paddingLeft: 8, paddingRight: 8 };
    } else {
      buttonStyle = { minWidth: 100, justifyContent: "center" };
    }

    buttonStyle = { ...smallStyle, ...buttonStyle };
  }

  // Setup default margins
  if (mr === true) {
    mr = 2;
  }

  if (ml === true) {
    ml = 2;
  }

  buttonStyle = {
    ...buttonStyle,
    ...getMargins({ m, mx, my, mt, mb, mr, ml }),
    ...style,
  };

  // Need to extract margins for tooltip
  const margins = {
    margin: buttonStyle.margin ?? 0,
    marginTop: buttonStyle.marginTop ?? 0,
    marginBottom: buttonStyle.marginBottom ?? 0,
    marginLeft: buttonStyle.marginLeft ?? 0,
    marginRight: buttonStyle.marginRight ?? 0,
  };

  // Remove margins if tooltip is present
  if (tooltip) {
    buttonStyle = {
      ...buttonStyle,
      margin: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    };
  }

  const ButtonComponentToRender = (
    <ButtonStyle
      $buttonType={type}
      $colors={colors}
      disabled={status === "retrieving" || disabled}
      onClick={onClick}
      style={buttonStyle}
      data-testid={testId}
      type={htmlType}
      value={
        value as
          | ((string | number | readonly string[]) &
              (string | number | boolean))
          | undefined // Casting all this because of weirdness with html wanting a readonly string[]
      }
    >
      {status === "ready" && icon && iconPos === "left" && (
        <Icon
          $left={text.length > 0}
          className={getButtonClassName(icon)}
          style={iconStyle}
        />
      )}
      {status === "retrieving" && (
        <SpinningIcon $left={affirmationText.length > 0} />
      )}
      <span>{buttonText}</span>
      {count && count > 0 ? <CountIcon>{count}</CountIcon> : null}
      {status === "ready" && icon && iconPos === "right" && (
        <Icon
          $right={text.length > 0}
          className={getButtonClassName(icon)}
          style={iconStyle}
        />
      )}
    </ButtonStyle>
  );

  if (tooltip) {
    return (
      // Pass margins into tooltip
      <ToolTip text={tooltip} direction={tooltipDirection} style={margins}>
        {ButtonComponentToRender}
      </ToolTip>
    );
  }

  return ButtonComponentToRender;
};

Button.displayName = "lh-button";

export default Button;
