import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import type { OptionProp } from "./Select";
import gridSpacing from "../../../utils/gridSpacing";

const Container = styled.div<{ $isSelected: boolean; $disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
  color: ${({ $disabled }) =>
    $disabled ? colorTheme("neutralL3") : colorTheme("neutralL1")};
  background: ${({ $isSelected }) =>
    $isSelected ? colorTheme("primaryL3") : ""};
  border-bottom: 1px solid transparent;
  border-color: ${({ $isSelected }) => $isSelected && colorTheme("white")};
  border-radius: 8px;

  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
    background: ${({ $isSelected }) => !$isSelected && colorTheme("primaryL4")};
  }
`;

const Description = styled.p`
  font-size: 12px;
  color: ${colorTheme("neutralL2")};
`;

const AvatarCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 500;
  background-color: ${colorTheme("primary")};
  color: ${colorTheme("white")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${gridSpacing[2]}px;
  text-transform: uppercase;
`;

const Option = (props: OptionProp) => {
  const disabled = Boolean(
    props?.data?.disabled || props?.innerProps?.disabled,
  );

  const renderIcon = () => {
    if (props.showAvatar) {
      const initials = props.data?.label?.split(" ").map((n) => n[0]);

      if (!initials || initials[0] === "-") {
        return null;
      }

      return (
        <AvatarCircle data-testid="avatar">
          {initials[0]}
          {initials[1] ?? ""}
        </AvatarCircle>
      );
    } else if (props.data?.imgSrc) {
      return (
        <img
          src={props.data.imgSrc}
          alt={props.data.label}
          style={{
            width: 28,
            height: 28,
            marginRight: gridSpacing[2],
          }}
        />
      );
    }

    return null;
  };

  const renderCheckboxOrRadio = () => {
    if (props.radio) {
      if (props.isSelected) {
        return (
          <i
            className="fa fa-check-circle"
            style={{
              color: colorTheme("primaryL1"),
              marginRight: gridSpacing[2],
            }}
          />
        );
      }

      return (
        <i
          className="fa-regular fa-circle"
          style={{
            color: colorTheme("neutralL3"),
            marginRight: gridSpacing[2],
          }}
        />
      );
    } else if (props.checkbox) {
      if (props.isSelected || props.data?.isSelected) {
        return (
          <i
            className="fa fa-check-square"
            style={{
              color: colorTheme("primaryL1"),
              marginRight: gridSpacing[2],
            }}
          />
        );
      }

      return (
        <i
          className="fa-regular fa-square"
          style={{
            color: colorTheme("neutralL3"),
            marginRight: gridSpacing[2],
          }}
        />
      );
    } else if (props?.data?.faIcon) {
      return (
        <i
          className={`fa ${props.data.faIcon}`}
          style={{
            width: 18,
            fontSize: 16,
            color: colorTheme("neutralL3"),
            paddingRight: gridSpacing[2],
          }}
        />
      );
    }
  };

  const Icon = renderIcon();
  const CheckboxOrRadio = renderCheckboxOrRadio();

  return (
    <Container
      onClick={(!disabled && props?.innerProps?.onClick) || props?.onClick}
      $disabled={disabled}
      $isSelected={Boolean(!props.radio && !props.checkbox && props.isSelected)}
    >
      {Icon}
      <div>
        <div style={{ display: "flex", alignItems: "center", ...props.style }}>
          {CheckboxOrRadio}
          {props.children || props.data?.label || props.label}
        </div>
        {props.data?.description && (
          <Description style={{ marginLeft: CheckboxOrRadio ? 23 : 0 }}>
            {props.data.description}
          </Description>
        )}
      </div>
    </Container>
  );
};

export default Option;
