import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import BreadCrumbs from "./BreadCrumbs/BreadCrumbs";
import Group from "./Group";
import SelectAll from "./SelectAll";
import { Popover, SearchText } from "../..";
import gridSpacing from "../../../../utils/gridSpacing";
import Option from "../Option";
import type { OptionProp, SelectProps, ValueProps } from "../Select";

const SelectBox = styled.div<{ $loading?: boolean; $disabled?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms;
  background-color: ${({ $loading, $disabled }) =>
    $loading || $disabled ? colorTheme("neutralL5") : colorTheme("white")};
  border-color: ${colorTheme("neutralL4")};
  color: ${colorTheme("neutralL2")};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 8px;
  pointer-events: ${({ $loading }) => ($loading ? "none" : "auto")};
  cursor: ${({ $loading, $disabled }) =>
    $loading ? "default" : $disabled ? "not-allowed" : "pointer"};

  &:hover {
    border-color: ${colorTheme("neutralL3")} !important;
  }
`;

const SelectBoxPill = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  background-color: ${colorTheme("neutralL4")};
  border-radius: 2px;
`;

const SelectBoxText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: colorTheme("neutral");
  font-size: 85%;
  padding: 3px;
  padding-left: 6px;
  box-sizing: border-box;
`;

const SelectBoxDelete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 3px 4px;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  color: ${colorTheme("neutral")};

  &:hover {
    background-color: ${colorTheme("warning")};
    color: ${colorTheme("danger")};
  }
`;

// Ripping off react-select chevron
const ChevronDownIcon = ({ menuIsOpen }: { menuIsOpen?: boolean }) => (
  <svg
    height="20"
    width="20"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    className="css-tj5bde-Svg"
  >
    <path
      style={{ color: menuIsOpen ? "#666" : "#ccc" }}
      // eslint-disable-next-line max-len
      d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
    />
  </svg>
);

// Took this from react select to match
const CloseIcon = () => (
  <svg
    height="14"
    width="14"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    className="css-tj5bde-Svg"
  >
    {/* eslint-disable-next-line max-len */}
    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
  </svg>
);

const hasOneValue = (
  value:
    | { label: string; value: string }
    | { label: string; value: string }[]
    | null,
): value is { label: string; value: string } =>
  Boolean(value && "value" in value && value?.value);

const AdvancedSelect = <TValue extends ValueProps>({
  label,
  loading = false,
  disabled = false,
  multiValues,
  type = "basic",
  checkbox = false,
  emptyOption = false,
  radio = false,
  hideSearch = false,
  showAvatars = false,
  showSelectAll = false,
  autoFocusSearch = false,
  noOptionsMessage = "No options",
  value,
  options,
  placeholder,
  onChange = () => null,
  customComponent,
  menuOpened,
  menuClosed,
  selectedValuesAtTop,
  closeMenu,
}: Omit<SelectProps<TValue>, "value"> & {
  value:
    | { label: string; value: string }
    | { label: string; value: string }[]
    | null;
}) => {
  const valueIsArray = Array.isArray(value);

  const selectBoxRef = useRef(null);

  const [menuIsOpen, setMenuIsOpen] = useState<boolean | undefined>(undefined);
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectBoxWidth, setSelectBoxWidth] = useState<number | undefined>(
    undefined,
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionProp[]>([]);

  useEffect(() => {
    const _maxWidth = (
      selectBoxRef.current as HTMLElement | null
    )?.getBoundingClientRect().width;
    if (_maxWidth) {
      setSelectBoxWidth(_maxWidth);
    }
  }, [selectBoxRef?.current]);

  useEffect(() => {
    if (menuIsOpen === false && menuClosed) {
      menuClosed();
    }

    if (menuIsOpen) {
      if (menuOpened) {
        menuOpened();
      }

      setSelectedOptions(
        options?.filter((option) =>
          valueIsArray
            ? value?.some((v) => v.value === option.value)
            : value?.value === option.value,
        ) ?? [],
      );
    }

    setSearchValue("");
  }, [menuIsOpen, closeMenu]);

  useEffect(() => {
    if (closeMenu) {
      setMenuIsOpen(false);
    }
  }, [closeMenu]);

  if (
    (multiValues === undefined || multiValues === null) &&
    (type === "breadcrumbs" || checkbox)
  ) {
    multiValues = true;
  }

  const filterOptions = (_options?: OptionProp[]): OptionProp[] => {
    const filterOption = (option: OptionProp) => {
      if (searchValue === "") {
        return true;
      }

      if (option.label?.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }

      let found = false;

      if (option.options) {
        found = option.options.some((o) => filterOption(o));
      }

      return found;
    };

    if (!_options) return [];

    return _options.filter(filterOption).map((o) => {
      return {
        ...o,
        options: o?.options && filterOptions(o.options),
      };
    });
  };

  const filteredOptions = filterOptions(options);

  if (emptyOption) {
    filteredOptions.unshift({ label: "-", value: "" });
  }

  const renderOptions = (options: OptionProp[]) =>
    options.map((option, index) => (
      <Option
        key={index}
        data={option}
        showAvatar={showAvatars}
        radio={radio}
        checkbox={checkbox}
        onClick={() => {
          if (multiValues) {
            const _value = valueIsArray ? value : [];

            const newValue = _value?.map((v) => v.value) || [];

            if (newValue?.some((o) => o === option.value)) {
              onChange(newValue?.filter((o) => o !== option.value) as TValue);
            } else {
              onChange([...newValue, `${option.value}`] as TValue);
            }
          } else {
            onChange(`${option.value}` as TValue);
            setMenuIsOpen(false);
          }
        }}
        isSelected={
          multiValues && valueIsArray
            ? value.some((v) => v.value === option.value)
            : (hasOneValue(value) && value?.value === option.value) || false
        }
      />
    ));

  const renderGroups = () => {
    let GroupComponent = Group;

    if (type === "breadcrumbs") {
      GroupComponent = BreadCrumbs;
    } else if (!filteredOptions.some((o) => o.options)) {
      // If there are no groups, just render the options
      if (filteredOptions.length === 0) {
        return (
          <div style={{ padding: 8, paddingLeft: 16 }}>{noOptionsMessage}</div>
        );
      }

      const returnOptions = filteredOptions.filter((option) => {
        const hasValue =
          multiValues && valueIsArray
            ? value.some((v) => v.value === option.value)
            : hasOneValue(value) && value?.value === option.value;

        if (
          selectedValuesAtTop &&
          selectedOptions.some((o) => o.value === option.value)
        ) {
          return false;
        } else if (
          radio ||
          checkbox ||
          customComponent ||
          selectedValuesAtTop
        ) {
          return true;
        }

        return !hasValue;
      });

      const filteredSelectedOptions = filterOptions(selectedOptions);

      return (
        <>
          <SelectAll
            visible={showSelectAll}
            type="basic"
            selectAll={() => {
              if (multiValues && valueIsArray) {
                let newValue = value?.map((v) => v.value) || [];
                const newMultiValues = filteredOptions.map(
                  (v) => v.value ?? "",
                );

                newValue = Array.from(
                  new Set([...newValue, ...newMultiValues]),
                );

                onChange(newValue as TValue);
              }
            }}
            deselectAll={() => {
              if (multiValues && valueIsArray) {
                const newValue = value?.map((v) => v.value) || [];
                const valuesToRemove = filteredOptions.map((v) => v.value);

                onChange(
                  newValue.filter((v) => !valuesToRemove.includes(v)) as TValue,
                );
              }
            }}
          />
          {selectedValuesAtTop && filteredSelectedOptions.length > 0 && (
            <div
              style={{
                borderBottom:
                  returnOptions.length > 0
                    ? `1px solid ${colorTheme("neutralL5")}`
                    : "none",
              }}
              data-testid="selected-options-container"
            >
              {renderOptions(filteredSelectedOptions)}
            </div>
          )}
          {renderOptions(returnOptions)}
        </>
      );
    }

    if (filteredOptions.length === 0 && !searchValue) {
      const message = noOptionsMessage;
      return <div style={{ padding: gridSpacing[2] }}>{message}</div>;
    }

    return (
      <GroupComponent
        label={label}
        filteredOptions={filteredOptions}
        options={options}
        checkbox={checkbox}
        radio={radio}
        openedGroups={openedGroups}
        setOpenedGroups={setOpenedGroups}
        noOptionsMessage={noOptionsMessage}
        type={type}
        selectedValuesAtTop={selectedValuesAtTop}
        showSelectAll={showSelectAll && multiValues}
        selectMultiple={(e, parentPath = undefined) => {
          if (multiValues && valueIsArray) {
            let newValue = value?.map((v) => v.value) || [];
            const newMultiValues = e.map((v) => v.value ?? "");

            newValue = Array.from(new Set([...newValue, ...newMultiValues]));
            onChange(newValue as TValue, parentPath);
          }
        }}
        deselectMultiple={(e, parentPath = undefined) => {
          if (multiValues && valueIsArray) {
            const newValue = value?.map((v) => v.value) || [];
            const valuesToRemove = e.map((v) => v.value);

            onChange(
              newValue.filter((v) => !valuesToRemove.includes(v)) as TValue,
              parentPath,
            );
          }
        }}
        value={
          multiValues && valueIsArray
            ? value.map((v) => v.value)
            : (hasOneValue(value) && value.value) || ""
        }
        selectOption={(e, parentPath = undefined) => {
          if (multiValues && valueIsArray) {
            const newValue = value?.map((v) => v.value) || [];

            if (newValue?.some((o) => o === e.value)) {
              onChange(
                newValue?.filter((o) => o !== e.value) as TValue,
                parentPath,
              );
            } else {
              onChange([...newValue, `${e.value}`] as TValue, parentPath);
            }
          } else {
            onChange(`${e.value}` as TValue, parentPath);
            setMenuIsOpen(false);
          }
        }}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );
  };

  const renderSelectBoxLabel = () => {
    if (loading) {
      return "Loading...";
    }

    if (value && valueIsArray && value?.length > 0) {
      return value.map((v) => (
        <SelectBoxPill key={v.value}>
          <SelectBoxText>{v.label}</SelectBoxText>

          <SelectBoxDelete
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            onClick={() =>
              onChange(
                value
                  .filter((o) => o.value !== v.value)
                  .map((o) => o.value) as TValue,
              )
            }
          >
            <CloseIcon />
          </SelectBoxDelete>
        </SelectBoxPill>
      ));
    }

    return placeholder ?? `Select ${label?.toLowerCase()}`;
  };

  if (loading || disabled)
    return (
      <SelectBox
        $loading={loading}
        $disabled={disabled}
        data-testid="adv-select-input"
      >
        <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
          {renderSelectBoxLabel()}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
          {loading && (
            <i
              data-testid="loading-indicator"
              className="fa fa-circle-notch fa-spin"
              style={{ marginRight: 16, color: colorTheme("neutralL3") }}
            />
          )}
          <ChevronDownIcon />
        </div>
      </SelectBox>
    );

  return (
    <Popover
      onOpenChange={() => {
        setMenuIsOpen(!menuIsOpen);
        setOpenedGroups([]);
      }}
      open={menuIsOpen}
    >
      <Popover.Trigger>
        {customComponent && <div>{customComponent}</div>}
        {!customComponent && (
          <SelectBox
            data-testid="adv-select-input"
            ref={selectBoxRef}
            onClick={(e) => {
              if (
                loading ||
                (e.target as HTMLElement)?.className?.includes("fa-times")
              ) {
                return;
              }
              setMenuIsOpen(true);
            }}
            style={{
              borderColor: menuIsOpen
                ? colorTheme("primary")
                : colorTheme("neutralL4"),
            }}
            $loading={loading}
          >
            <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
              {renderSelectBoxLabel()}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              {valueIsArray && value?.length > 0 && (
                <i
                  className="fa-solid fa-times"
                  style={{ color: colorTheme("neutralL3"), cursor: "pointer" }}
                  onClick={() => onChange([] as unknown as TValue)}
                />
              )}
              <ChevronDownIcon menuIsOpen={menuIsOpen} />
            </div>
          </SelectBox>
        )}
      </Popover.Trigger>
      <Popover.Content align="start">
        <div
          style={{
            marginTop: 5,
            minWidth: 325,
            width: selectBoxWidth,
            background: colorTheme("white"),
            border: `1px solid ${colorTheme("neutralL4")}`,
            borderRadius: 8,
            boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.5)",
          }}
        >
          <div>
            <div>
              {!hideSearch && options && options.length > 0 && (
                <div>
                  {/* This button is a hack so the user won't focus on the search textbox */}
                  {!autoFocusSearch && (
                    <button style={{ position: "absolute", opacity: 0 }} />
                  )}
                  {type !== "breadcrumbs" && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: gridSpacing[2],
                      }}
                    >
                      <SearchText
                        fixedWidth
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        testId="adv-select-search"
                        style={{
                          width: "100%",
                          border: "none",
                          borderRadius: 0,
                          borderTopRightRadius: 8,
                          borderTopLeftRadius: 8,
                          borderBottom: `1px solid ${colorTheme("neutralL4")}`,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                paddingTop:
                  options && options.length > 0 && !hideSearch
                    ? 0
                    : gridSpacing[2],
                paddingBottom: gridSpacing[2],
                maxHeight: `min(calc(var(--radix-popper-available-height) - ${
                  type === "breadcrumbs" ? 0 : 39
                }px), 500px)`,
                overflowY: "auto",
              }}
            >
              {renderGroups()}
            </div>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};
export default AdvancedSelect;
