import type { FC, ReactNode } from "react";
import React, {
  createContext,
  isValidElement,
  useContext,
  useState,
} from "react";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import "./styles.css";
import styled, { css } from "styled-components";

import { colorTheme } from "@utils";

import { Collapse } from "..";
import gridSpacing from "../../../utils/gridSpacing";

const widthMapping = (inputWidth: number) => {
  if (inputWidth === 1) {
    return 120;
  } else if (inputWidth === 2) {
    return 165;
  } else if (inputWidth === 3) {
    return 280;
  }
  return inputWidth;
};

//region Styled Components
const StyledDropdownMenuContent = styled(DropdownMenu.Content)<{
  width: number;
  height?: number;
  isRow: boolean;
}>`
  background-color: #fff;
  border: 1px solid #ebebeb;
  min-width: ${({ width }) => `${widthMapping(width)}px;`};
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  ${({ isRow }) =>
    isRow &&
    css`
      display: flex;
      align-items: center;
    `}
  border-radius: 6px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.07);
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 121;

  //region Content Animation
  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }

  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }

  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  //endregion
`;

const FilterDropdownToggle = styled.div<{
  active: boolean;
}>`
  ${({ active }) =>
    active
      ? `
          background-color: ${colorTheme("primaryL4")};
          border: 1px solid ${colorTheme("primaryL1")};
          color: ${colorTheme("primaryL1")};
        `
      : `
          background-color: ${colorTheme("neutralL4")};
          border: 1px solid ${colorTheme("neutralL3")};
        `};
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: ${gridSpacing[2]}px;
  white-space: nowrap;
  max-height: 50px;

  &:hover {
    cursor: pointer;
  }
`;

const SearchDropdownToggle = styled.div`
  background-color: ${colorTheme("neutralL5")};
  color: ${colorTheme("neutral")};
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: ${gridSpacing[2]}px;
  box-sizing: border-box;
  white-space: nowrap;
  max-height: 50px;

  &:hover {
    cursor: pointer;
  }
`;

const dropDownEntryStyles = css`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  user-select: none;
  outline: none;
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
  box-sizing: border-box;
  white-space: nowrap;
  max-height: 50px;
`;

const dropDownItemStyles = css<{
  isRow: boolean;
}>`
  ${dropDownEntryStyles};

  ${({ isRow }) =>
    !isRow
      ? `
          &:not(:last-child):after {
              content: "";
              position: absolute;
              bottom: 0;
              width: 90%;
              height: 1px;
              background-color: ${colorTheme("neutralL5")};
              marginX: auto;
          }
        `
      : `
          height: 100%;
          flex: 1;
          justify-content: center;
        `}

  &:hover {
    cursor: pointer;
  }

  &[data-disabled] {
    color: ${colorTheme("neutralL3")};
    pointer-events: none;
  }

  &[data-highlighted] {
    background-color: ${colorTheme("primaryL4")};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${colorTheme("neutralL5")};
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${colorTheme("neutralL5")};
    }
  }
`;

const StyledDropdownMenuItem = styled(DropdownMenu.Item)<{
  isRow: boolean;
}>`
  ${dropDownItemStyles};
`;

const StyledDropdownMenuRadioItem = styled(DropdownMenu.RadioItem)<{
  isRow: boolean;
}>`
  ${dropDownItemStyles};
  gap: ${gridSpacing[2]}px;
`;

const StyledDropdownMenuLabel = styled(DropdownMenu.Label)<{
  collapsible: boolean;
}>`
  ${dropDownEntryStyles};
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${colorTheme("neutralL1")};

  ${({ collapsible }) =>
    collapsible &&
    `
    &:hover {
      cursor: pointer;
      background-color: ${colorTheme("neutralL6")};
    }
    `};
`;

const ClearDropdownMenuItem = styled(DropdownMenu.Item)`
  ${dropDownEntryStyles};
  color: ${colorTheme("primary")};
  justify-content: flex-end;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${colorTheme("neutralL5")};
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const DropDownMenuSearchContainer = styled.div`
  ${dropDownEntryStyles};
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
  pointer-events: none;
  display: flex;
  height: 52px;
`;

const StyledDropdownMenuSearch = styled.input`
  pointer-events: auto;
  border: 1px solid ${colorTheme("neutralL4")};
  border-radius: 6px;
  outline: none;
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
  width: 100%;
  font-size: 16px;

  &::placeholder {
    color: ${colorTheme("neutralL2")};
    font-size: 16px;
  }
`;

const DropDownMenuScrollContainer = styled.div<{ isRow: boolean }>`
  ${({ isRow }) =>
    isRow &&
    `
    display: flex;
    flex-direction: row;
    align-items: center;
  `};

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Badge = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${colorTheme("primaryL1")};
  border-radius: 50%;
  color: ${colorTheme("white")};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
`;

//endregion

interface Option {
  label: string;
  value: string;
  color?: string;
  icon?: string;
}

type GroupOption = { label: string; options: Array<Option> };

type OptionTypes = string | Option | GroupOption;

type CommonRootProps = {
  closeOnSelect?: boolean;
  openCallback?: () => void;
  closeCallback?: () => void;
  contentAlign?: "start" | "center" | "end";
  sideOffset?: number;
  alignOffset?: number;
  title?: string;
  options?: Array<OptionTypes>;
  value?: string;
  onChange?: (value: string) => void;
  hasSearch?: boolean;
  onSearchChange?: (value: string) => void;
  searchPlaceholder?: string;
  hasClear?: boolean;
  height?: number;
  width?: number;
  isRow?: boolean;
};

function isGroupOption(option: OptionTypes): option is GroupOption {
  return (option as GroupOption).options !== undefined;
}

type RootProps = CommonRootProps & {
  direction?: "up" | "down" | "left" | "right";
  children?: ReactNode;
  testId?: string;
};

type DropDownV2ContextType = CommonRootProps & {
  width: number;
  isRow: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onChange: (value: string) => void;
  direction?: "top" | "bottom" | "left" | "right";
  rootTestId?: string;
};

const DropDownV2Context = createContext<DropDownV2ContextType>({
  closeOnSelect: true,
  openCallback: () => {},
  closeCallback: () => {},
  contentAlign: "start",
  sideOffset: 0,
  alignOffset: 0,
  title: undefined,
  options: undefined,
  value: undefined,
  onChange: () => {},
  hasSearch: false,
  onSearchChange: () => {},
  searchPlaceholder: undefined,
  hasClear: false,
  height: undefined,
  width: 2,
  isRow: false,
  direction: "bottom",
  searchValue: "",
  setSearchValue: () => {},
  rootTestId: "",
});

const getOptionValue = (option: OptionTypes) => {
  if (typeof option === "string") return option;
  if ("value" in option) return option.value;

  return "";
};

const getOptionLabel = (option: OptionTypes) => {
  if (typeof option === "string") return option;

  let color = "inherit";
  if ("color" in option && option.color) {
    color = option.color;
  }

  return (
    <span style={{ color: color }}>
      {"icon" in option && option.icon && (
        <i className={option.icon} style={{ paddingRight: gridSpacing[1] }} />
      )}
      {option.label}
    </span>
  );
};

type ToggleProps = {
  children: ReactNode;
  testId?: string;
};

const Toggle: FC<ToggleProps> = ({ children, testId }) => (
  <DropdownMenu.Trigger
    onPointerDown={(e) => {
      if (e.target instanceof HTMLButtonElement) {
        if (e.target.dataset.disabletoggle) e.preventDefault();
      }
    }}
    data-testid={testId}
  >
    {children}
  </DropdownMenu.Trigger>
);

type MenuProps = {
  children: ReactNode;
  testId?: string;
};

const Menu: FC<MenuProps> = ({ children, testId = "dropdown-v2__menu" }) => {
  const context = useContext(DropDownV2Context);

  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);

  const renderOptions = () => {
    if (!context.options || context.options.length === 0) {
      return null;
    }

    if (context.hasSearch) {
      if (
        context.options &&
        Array.isArray(context.options) &&
        context.options.every(isGroupOption)
      ) {
        return context.options.map((group, i) => {
          const groupOptions = group.options.filter(({ label }) =>
            label.toLowerCase().includes(context.searchValue.toLowerCase()),
          );
          let groupCollapsed = collapsedGroups.includes(`group${i}`);

          if (context.searchValue && groupOptions.length > 0) {
            groupCollapsed = false;
          }

          return (
            <div key={i}>
              <StyledDropdownMenuLabel
                data-testid={`${testId}__group-label-${group.label}`}
                collapsible
                onClick={() => {
                  if (groupCollapsed) {
                    setCollapsedGroups(
                      collapsedGroups.filter((g) => g !== `group${i}`),
                    );
                  } else {
                    setCollapsedGroups([...collapsedGroups, `group${i}`]);
                  }
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    className={`fa fa-chevron-${
                      groupCollapsed ? "right" : "down"
                    }`}
                    style={{ marginRight: gridSpacing[1] }}
                  />
                  {group.label}
                </div>
                <Badge>{groupOptions.length}</Badge>
              </StyledDropdownMenuLabel>
              <Collapse isOpened={!groupCollapsed}>
                {groupOptions.map((option) => (
                  <StyledDropdownMenuItem
                    key={getOptionValue(option)}
                    onSelect={() => context.onChange(getOptionValue(option))}
                    isRow={context.isRow}
                    data-testid={`${testId}__option-${getOptionValue(option)}`}
                  >
                    {getOptionLabel(option)}
                  </StyledDropdownMenuItem>
                ))}
              </Collapse>
            </div>
          );
        });
      }

      return (
        <>
          {context.options
            .filter((o) =>
              typeof o === "string"
                ? o.toLowerCase().includes(context.searchValue.toLowerCase())
                : o.label
                    .toLowerCase()
                    .includes(context.searchValue.toLowerCase()),
            )
            .map((option) => (
              <StyledDropdownMenuItem
                key={getOptionValue(option)}
                onSelect={() => context.onChange(getOptionValue(option))}
                isRow={context.isRow}
                data-testid={`${testId}__option-${getOptionValue(option)}`}
              >
                {getOptionLabel(option)}
              </StyledDropdownMenuItem>
            ))}
        </>
      );
    }

    return (
      <DropdownMenu.RadioGroup
        value={context.value}
        onValueChange={context.onChange}
      >
        {context.options.map((option) => (
          <StyledDropdownMenuRadioItem
            value={getOptionValue(option)}
            isRow={context.isRow}
            key={getOptionValue(option)}
            data-testid={`${testId}__option-${getOptionValue(option)}`}
          >
            {context.value === getOptionValue(option) ? (
              <i
                className="fa-solid fa-check-circle"
                style={{ color: colorTheme("primaryL1") }}
              />
            ) : (
              <i
                className="fa-regular fa-circle"
                style={{ color: colorTheme("neutralL1") }}
              />
            )}
            {getOptionLabel(option)}
          </StyledDropdownMenuRadioItem>
        ))}
      </DropdownMenu.RadioGroup>
    );
  };

  return (
    <DropdownMenu.Portal>
      <StyledDropdownMenuContent
        className="DropdownMenuContent"
        side={context.direction}
        sideOffset={context.sideOffset}
        alignOffset={context.alignOffset}
        align={context.contentAlign}
        width={context.width}
        height={context.height}
        isRow={context.isRow}
        style={{ display: context.isRow ? "flex" : "block" }}
        data-testid={testId}
      >
        {context.title && (
          <StyledDropdownMenuLabel collapsible={false}>
            {context.title}
          </StyledDropdownMenuLabel>
        )}
        {context.hasSearch && (
          <DropDownMenuSearchContainer>
            <StyledDropdownMenuSearch
              value={context.searchValue}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                context.setSearchValue(e.target.value);
                context.onSearchChange &&
                  context.onSearchChange(e.target.value);
              }}
              placeholder={context.searchPlaceholder || "Search"}
            />
          </DropDownMenuSearchContainer>
        )}
        <DropDownMenuScrollContainer isRow={context.isRow}>
          {children}
          {renderOptions()}
        </DropDownMenuScrollContainer>
        {context.hasClear && (
          <ClearDropdownMenuItem
            onClick={() => {
              context.onChange("");
              context.setSearchValue("");
            }}
            data-testid={`${testId}__clear`}
          >
            Clear
          </ClearDropdownMenuItem>
        )}
      </StyledDropdownMenuContent>
    </DropdownMenu.Portal>
  );
};

type ItemProps = {
  onClick?: () => void;
  children: ReactNode;
  value: string;
  testId?: string;
};

const Item: FC<ItemProps> = ({
  children,
  onClick,
  value,
  testId = "dropdown-v2__menu__item",
}) => {
  const context = useContext(DropDownV2Context);

  const { closeOnSelect, isRow } = context;
  return (
    <StyledDropdownMenuItem
      isRow={isRow}
      onClick={onClick}
      onSelect={(e) => {
        context.onChange(value);
        if (!closeOnSelect) {
          e.preventDefault();
        }
        return e;
      }}
      onPointerUp={(e) => {
        e.preventDefault();
      }}
      data-testid={testId}
    >
      {children}
    </StyledDropdownMenuItem>
  );
};

interface RootComponent extends FC<RootProps> {
  Toggle: typeof Toggle;
  Menu: typeof Menu;
  Item: typeof Item;
}

const Root: RootComponent = ({
  width = 2,
  direction: _direction = "down",
  isRow = false,
  children,
  closeOnSelect = true,
  openCallback,
  closeCallback,
  contentAlign = "start",
  sideOffset = 0,
  alignOffset = 0,
  title,
  options = [],
  value,
  onChange = () => {},
  hasSearch,
  onSearchChange = () => {},
  searchPlaceholder,
  hasClear,
  height,
  testId = "dropdown-v2",
}) => {
  const [searchValue, setSearchValue] = useState("");

  const mapDirection = (direction: "up" | "down" | "left" | "right") => {
    if (direction === "down") {
      return "bottom";
    } else if (direction === "up") {
      return "top";
    } else if (direction === "left") {
      return "left";
    } else if (direction === "right") {
      return "right";
    }
  };

  const direction = mapDirection(_direction);

  const hasToggleComponent = React.Children.toArray(children).some(
    (child) => isValidElement(child) && child.type === Toggle,
  );
  const hasMenuComponent = React.Children.toArray(children).some(
    (child) => isValidElement(child) && child.type === Menu,
  );

  const filterActive = !!value;

  const renderToggle = () => {
    if (hasToggleComponent) return null;

    if (hasSearch) {
      const optionFilter = (option: OptionTypes) => {
        if (typeof option === "string") return option === value;

        if ("value" in option) return option.value === value;

        return false;
      };

      let filteredOption = null;

      if (options && Array.isArray(options) && options.every(isGroupOption)) {
        const foundOption = options.find((group) =>
          group.options.some((option) => value === option.value),
        );

        if (foundOption) {
          const foundOptionValue = foundOption.options.find(
            (option) => value === option.value,
          );
          if (foundOptionValue) {
            filteredOption = foundOptionValue.label;
          }
        }
      } else {
        filteredOption = options.find(optionFilter);
      }

      return (
        <Toggle testId={`${testId}__search-toggle`}>
          <SearchDropdownToggle>
            {typeof filteredOption === "string"
              ? filteredOption
              : filteredOption?.label || value || searchPlaceholder}
          </SearchDropdownToggle>
        </Toggle>
      );
    }

    return (
      <Toggle testId={`${testId}__filter-toggle`}>
        <FilterDropdownToggle active={filterActive}>
          {filterActive ? "Filter Active" : "Filter"}
          {filterActive ? (
            <i
              data-disabletoggle="true"
              className="fa fa-x"
              style={{
                fontSize: "12px",
                marginTop: "2px",
              }}
              onClick={() => onChange("")}
            />
          ) : (
            <i
              className="fa fa-chevron-up"
              style={{
                fontSize: "12px",
                marginTop: "2px",
              }}
            />
          )}
        </FilterDropdownToggle>
      </Toggle>
    );
  };

  return (
    <DropDownV2Context.Provider
      value={{
        direction,
        contentAlign,
        closeOnSelect,
        sideOffset,
        alignOffset,
        title,
        options,
        value,
        onChange,
        hasSearch,
        searchValue,
        onSearchChange,
        setSearchValue,
        searchPlaceholder,
        hasClear,
        width,
        height,
        isRow,
        rootTestId: testId,
      }}
    >
      <DropdownMenu.Root
        onOpenChange={(open) => {
          if (open) {
            if (openCallback) {
              openCallback();
            }
          } else {
            if (closeCallback) {
              closeCallback();
            }
          }
        }}
        data-testid={testId}
      >
        {renderToggle()}
        {hasMenuComponent ? (
          <>{children}</>
        ) : (
          <Menu testId={`${testId}__menu`}>{children}</Menu>
        )}
      </DropdownMenu.Root>
    </DropDownV2Context.Provider>
  );
};

Root.Menu = Menu;
Root.Toggle = Toggle;
Root.Item = Item;

export default Root;
