import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import { Table, TableLink } from "@commonComponents";
import { PROVIDER_360_URLS } from "@urls";

import { gridSpacing } from "@utils";
import { numberWithCommas } from "@utils";

import {
  capacityAnalysisDimensions,
  assignedDrillDownQuery,
  panelStatisticsQuery,
  providerSeenQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import { rollUpProvidersToPanels } from "../Common/attributionCommon";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { parseToFixed } from "~/src/utils/stringUtils";
import type {
  CubeQueryResultRawDataType,
  CubeTableColumns,
} from "~/src/utils/tsUtils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

export const panelStatisticColumns: CubeTableColumns<
  typeof panelStatisticsQuery
> = [
  {
    Header: "Name",
    accessor: "assigned_drilldown.panel_name",
    Cell: (props) => (
      <TableLink
        newTab
        url={`${PROVIDER_360_URLS.ATTRIBUTION_PANELS}/view/${props.row.original["assigned_drilldown.panel_id"]}`}
      >
        {props.row.original["assigned_drilldown.panel_name"]}
      </TableLink>
    ),
    sticky: "left",
  },
  {
    Header: "Total Assigned",
    accessor: "assigned_drilldown.total_assigned",
    Cell: (props) =>
      numberWithCommas(props.row.original["assigned_drilldown.total_assigned"]),
  },
  {
    Header: "Base Capacity",
    accessor: "assigned_drilldown.total_base_capacity",
    Cell: (props) =>
      numberWithCommas(
        props.row.original["assigned_drilldown.total_base_capacity"],
      ),
  },
  {
    Header: "Base Capacity Ratio",
    Cell: (props) =>
      parseToFixed({
        input:
          parseInt(props.row.original["assigned_drilldown.total_assigned"]) /
          parseInt(
            props.row.original["assigned_drilldown.total_base_capacity"],
          ),
        multiplier: 100,
        postFix: "%",
      }),
  },
  {
    Header: "Total Capacity",
    accessor: "assigned_drilldown.total_max_capacity",
    Cell: (props) =>
      numberWithCommas(
        props.row.original["assigned_drilldown.total_max_capacity"],
      ),
  },
  {
    Header: "Total Capacity Ratio",
    Cell: (props) =>
      parseToFixed({
        input:
          parseInt(props.row.original["assigned_drilldown.total_assigned"]) /
          parseInt(props.row.original["assigned_drilldown.total_max_capacity"]),
        multiplier: 100,
        postFix: "%",
      }),
  },
  {
    Header: "Assigned Provider Seen",
    accessor: "assigned_drilldown.total_assigned_540_day_flag",
    Cell: (props) =>
      parseToFixed({
        input:
          parseFloat(
            props.row.original[
              "assigned_drilldown.total_assigned_540_day_flag"
            ],
          ) /
          parseFloat(props.row.original["assigned_drilldown.total_assigned"]),
        postFix: "%",
        multiplier: 100,
      }),
  },
  {
    Header: "Any Provider Seen",
    accessor: "assigned_drilldown.total_any_540_day_flag",
    Cell: (props) =>
      parseToFixed({
        input:
          parseFloat(
            props.row.original["assigned_drilldown.total_any_540_day_flag"],
          ) /
          parseFloat(props.row.original["assigned_drilldown.total_assigned"]),
        postFix: "%",
        multiplier: 100,
      }),
  },
];

export const getPayerSeenBarChartData = (
  providerSeenResult:
    | CubeQueryResultRawDataType<typeof providerSeenQuery>
    | undefined,
) => {
  if (!providerSeenResult) return { data: [], options: [] };
  const providerSeenData = providerSeenResult[0];

  if (!providerSeenData) return { data: [], options: [] };

  const orderedKeys = ["730", "540", "365", "90", "30"];

  const organizedProviderData: Record<
    string,
    { seen: number; assigned_seen: number }
  > = {
    "30": {
      seen: 0,
      assigned_seen: 0,
    },
    "90": {
      seen: 0,
      assigned_seen: 0,
    },
    "365": {
      seen: 0,
      assigned_seen: 0,
    },
    "540": {
      seen: 0,
      assigned_seen: 0,
    },
    "730": {
      seen: 0,
      assigned_seen: 0,
    },
  };

  const getTimeString = (key: string) => {
    for (const time of orderedKeys) {
      if (key.includes(time)) {
        return time;
      }
    }
    return "";
  };

  Object.keys(providerSeenData).forEach((_key: string) => {
    const key = _key as keyof typeof providerSeenData;
    if (key.includes("any_provider")) {
      const time = getTimeString(key);

      if (organizedProviderData?.[time]) {
        organizedProviderData[time] = {
          ...organizedProviderData[time],
          seen: parseInt(providerSeenData[key]),
        };
      }
    } else if (key.includes("attributed_provider")) {
      const time = getTimeString(key);

      if (organizedProviderData?.[time]) {
        organizedProviderData[time] = {
          ...organizedProviderData[time],
          assigned_seen: parseInt(providerSeenData[key]),
        };
      }
    }
  });

  return {
    data: [
      {
        name: "Any Seen",
        data: orderedKeys.map((key) => organizedProviderData[key].seen),
      },
      {
        name: "Assigned Seen",
        data: orderedKeys.map(
          (key) => organizedProviderData[key].assigned_seen,
        ),
      },
    ],
    options: ["24 months", "18 months", "12 months", "3 months", "1 month"],
  };
};

const CapacityAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: capacityAnalysisDimensions,
  });

  const {
    resultSet: assignedDrillDownResult,
    error: assignedDrillDownError,
    isLoading: assignedDrillDownLoading,
  } = useCubeApiQuery(assignedDrillDownQuery(filters, filterOrder));

  const {
    resultSet: providerSeenResult,
    error: providerSeenError,
    isLoading: providerSeenLoading,
  } = useCubeApiQuery(providerSeenQuery());

  const { resultSet: panelStatisticResult, isLoading: panelStatisticLoading } =
    useCubeApiQuery(panelStatisticsQuery(filters, filterOrder));

  const totalAssigned = (assignedDrillDownResult?.rawData() ?? [])
    .map((data) => parseFloat(data["assigned_drilldown.total_assigned"]) || 0)
    .reduce((acc, curr) => acc + curr, 0);
  const totalBaseCapacity = (assignedDrillDownResult?.rawData() ?? [])
    .map(
      (data) => parseFloat(data["assigned_drilldown.total_base_capacity"]) || 0,
    )
    .reduce((acc, curr) => acc + curr, 0);
  const totalMaxCapacity = (assignedDrillDownResult?.rawData() ?? [])
    .map(
      (data) => parseFloat(data["assigned_drilldown.total_max_capacity"]) || 0,
    )
    .reduce((acc, curr) => acc + curr, 0);

  const panelStatisticData = rollUpProvidersToPanels(
    panelStatisticResult?.rawData() ?? [],
  );

  return (
    <>
      <DataViz.Row>
        <DataViz.Gauge
          loading={assignedDrillDownLoading}
          error={assignedDrillDownError && "Something went wrong"}
          title="Base Capacity Ratio"
          toolTip="Measurement of total individuals on roster compare to base capacity"
          numeratorTitle="Assigned"
          numerator={totalAssigned}
          denominatorTitle="Base"
          denominator={totalBaseCapacity}
        />
        <DataViz.Gauge
          loading={assignedDrillDownLoading}
          error={assignedDrillDownError && "Something went wrong"}
          title="Total Capacity Ratio"
          toolTip="Measurement of total individuals on roster compare to total capacity (base + flex)"
          numeratorTitle="Assigned"
          numerator={totalAssigned}
          denominatorTitle="Total"
          denominator={totalMaxCapacity}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.BarChart
          title="Providers Seen"
          toolTip="Count of assigned individual that have seen assigned or any provider within the practice"
          loading={providerSeenLoading}
          error={providerSeenError && "Something went wrong"}
          yAxisLabel="Consumers"
          {...getPayerSeenBarChartData(providerSeenResult?.rawData())}
        />
      </DataViz.Row>
      <Header
        title="Panel Statistics"
        toolTip="Key top level metrics of panels"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={panelStatisticLoading}
        columns={panelStatisticColumns}
        data={panelStatisticData ?? []}
        style={{ marginBottom: gridSpacing[5] }}
      />
    </>
  );
};

export default CapacityAnalysis;
