import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { colorTheme } from "@utils";

export const StyledDivider = styled.hr`
  width: 100%;
  border-top: 1px solid ${colorTheme("neutralL4")};
  border-left: 0px;
`;

const Divider = ({ style }) => <StyledDivider style={style} />;

Divider.propTypes = {
  style: PropTypes.object,
  colorTheme: PropTypes.object,
};

export default Divider;
