import React from "react";

import {
  Button,
  StatusPill,
  SubTextCell,
  TableLink,
  ToolTip,
} from "@commonComponents";

import { gridSpacing } from "@utils";
import { capitalizeFirstLetters, colorTheme } from "@utils";

import FavoriteButton from "./FavoriteButton";
import { checkGroupPermission } from "./helpers";
import type { ListInsightsDashboard, UserFavorites } from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";
import { internalPageSuccess } from "~/src/utils/tsUtils";

const copyLink = (path: string) => {
  navigator.clipboard.writeText(window.location.host + path);
  internalPageSuccess("A shareable link has been copied to clipboard");
};

export const groupColumns = (
  favorites: UserFavorites[],
  getFavorites: () => void,
) => [
  {
    Header: "Name",
    accessor: "name",
    Cell: (props: { row: { original: { path: string } }; value: string }) => {
      const pathArr = props.row.original.path.split("/");
      const subject = pathArr[2];
      const group = pathArr[3];
      const hasPermission = checkGroupPermission(subject, group);

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <i
            className={
              hasPermission ? "fa-regular fa-folder" : "fa-solid fa-lock"
            }
            style={{
              marginRight: gridSpacing[2],
              color: colorTheme("neutralL2"),
            }}
          />
          {hasPermission ? (
            <TableLink url={props.row.original.path}>{props.value}</TableLink>
          ) : (
            <ToolTip text="You do not have permission">
              <div style={{ cursor: "not-allowed" }}>{props.value}</div>
            </ToolTip>
          )}
        </div>
      );
    },
  },
  {
    Header: "Description",
    accessor: "shortDescription",
  },
  {
    accessor: "path",
    width: 115,
    disableResizing: true,
    Cell: (props: {
      row: { original: { path: string; uuid: string } };
      value: string;
    }) => {
      const pathArr = props.row.original.path.split("/");
      const subject = pathArr[2];
      const group = pathArr[3];
      const hasPermission = checkGroupPermission(subject, group);

      if (hasPermission) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="link"
              icon="fa-link"
              onClick={() => copyLink(props.value)}
              tooltip="Copy link"
            />
            <ToolTip text="Favorite">
              <FavoriteButton
                favorite={favorites.some(
                  (favorite) =>
                    favorite.object_type === "leap_insights_subdomain" &&
                    favorite.object_id === props.row.original.uuid,
                )}
                objectType="leap_insights_subdomain"
                objectId={props.row.original.uuid}
                refreshFavorites={getFavorites}
              />
            </ToolTip>
          </div>
        );
      }
      return <div style={{ height: 42 }} />;
    },
  },
];

export const quicksightDashboardColumns = () => [
  {
    Header: "Name",
    accessor: "name",
    Cell: (props: { row: { original: { path: string } }; value: string }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <i
          className="fa-solid fa-table-layout"
          style={{
            marginRight: gridSpacing[2],
            color: colorTheme("discovery"),
          }}
        />
        <TableLink
          url={`${LEAP_INSIGHTS_URLS.baseUrl}/${props.row.original.path}`}
        >
          {props.value}
        </TableLink>
      </div>
    ),
  },
  {
    Header: "Dashboard Type",
    accessor: "type",
    maxWidth: 50,
    Cell: () => (
      <StatusPill
        style={{
          color: colorTheme("white"),
          backgroundColor: colorTheme("discovery"),
        }}
        type="discovery"
        text="Quicksight"
      />
    ),
  },
  {
    accessor: "path",
    width: 165,
    disableResizing: true,
    Cell: (props: { value: string }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          type="link"
          icon="fa-link"
          onClick={() =>
            copyLink(`${LEAP_INSIGHTS_URLS.baseUrl}/${props.value}`)
          }
          tooltip="Copy link"
          style={{ marginLeft: gridSpacing[4] }}
        />
      </div>
    ),
  },
];
export const dashboardColumns = (
  setSelectedDashboard: (_dashboard: ListInsightsDashboard) => void,
  favoritesData: UserFavorites[],
  getFavorites: () => void,
  customerName: string,
) => [
  {
    Header: "Name",
    accessor: "name",
    Cell: (props: {
      row: {
        original: {
          path: string;
          dashboard_type: string;
          subdomain: {
            analytic_domain: { name: string };
            name: string;
          };
        };
      };
      value: string;
    }) => (
      <SubTextCell
        content={
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-regular fa-table-layout"
              style={{
                marginRight: gridSpacing[2],
                color:
                  props.row.original.dashboard_type === "customer"
                    ? colorTheme("neutralL2")
                    : colorTheme("info"),
              }}
            />
            <TableLink
              url={`${LEAP_INSIGHTS_URLS.baseUrl}/${props.row.original.path}`}
            >
              {props.value}
            </TableLink>
          </div>
        }
        subText={`${props.row.original.subdomain.analytic_domain.name} / ${props.row.original.subdomain.name}`}
      />
    ),
  },
  {
    Header: "Managed By",
    accessor: "managed_by",
    maxWidth: 50,
    Cell: (props: { value: string }) => (
      <StatusPill
        style={{
          backgroundColor:
            props.value === "customer"
              ? colorTheme("neutralL2")
              : colorTheme("info"),
          color: colorTheme("white"),
        }}
        text={
          props.value === "customer"
            ? customerName
            : capitalizeFirstLetters(props.value)
        }
      />
    ),
  },
  {
    Header: "Dashboard Type",
    accessor: "type",
    maxWidth: 50,
    Cell: (props: { value: string }) => (
      <StatusPill
        style={{
          backgroundColor:
            props.value === "standard"
              ? colorTheme("info")
              : colorTheme("neutralL2"),
          color: colorTheme("white"),
        }}
        text={
          props.value === "customer"
            ? customerName
            : capitalizeFirstLetters(props.value)
        }
      />
    ),
  },
  {
    accessor: "path",
    width: 165,
    disableResizing: true,
    Cell: (props: {
      row: {
        original: ListInsightsDashboard;
      };
      value: string;
    }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <ToolTip text="Favorite">
          <FavoriteButton
            favorite={favoritesData.some(
              (favorite) =>
                favorite.object_type &&
                ["leap_insights_lucerna", "leap_insights_customer"].includes(
                  favorite.object_type,
                ) &&
                favorite.object_id === props.row.original.uuid,
            )}
            objectType={
              props.row.original.dashboard_type === "lucerna"
                ? "leap_insights_lucerna"
                : "leap_insights_customer"
            }
            objectId={
              props.row.original.uuid ??
              props.row.original.path.split("leap-insights/")[1]
            }
            refreshFavorites={getFavorites}
          />
        </ToolTip>

        <Button
          style={{ marginLeft: gridSpacing[4] }}
          type="link"
          icon="fa-link"
          onClick={() =>
            copyLink(`${LEAP_INSIGHTS_URLS.baseUrl}/${props.value}`)
          }
          tooltip="Copy link"
        />

        <Button
          type="link"
          icon="fa-info-circle"
          onClick={() => setSelectedDashboard(props.row.original)}
          tooltip="Info"
        />
      </div>
    ),
  },
];
