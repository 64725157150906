import React, { useEffect } from "react";

import { StatusPill, ToolTip } from "@commonComponents";
import { useLocation } from "react-router";

import { gridSpacing } from "@utils";
import { colorTheme } from "@utils";

import DashboardItem from "./DashboardItem";
import Item from "./Item";
import { checkGroupPermission } from "../helpers";
import type { ListInsightsDashboard, SubDomain } from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";

type GroupItemProps = {
  group: SubDomain;
  subjectPath: string;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  insightsDashboards: ListInsightsDashboard[];
  isLoading: boolean;
  selectedObject: string;
  setSelectedObject: (value: string) => void;
};

const GroupItem = ({
  group,
  subjectPath,
  collapsed,
  toggleCollapsed,
  insightsDashboards,
  isLoading,
  selectedObject,
  setSelectedObject,
}: GroupItemProps) => {
  const location = useLocation();
  const hasPermission = checkGroupPermission(subjectPath, group.path);

  useEffect(() => {
    if (
      collapsed &&
      hasPermission &&
      location.pathname.includes(`${subjectPath}/${group.path}`) &&
      toggleCollapsed
    ) {
      toggleCollapsed();
    }
  }, [location.pathname]);

  const groupPath = `${LEAP_INSIGHTS_URLS.baseUrl}/${subjectPath}/${group.path}`;

  return (
    <ToolTip
      direction="right"
      text={!hasPermission ? "You do not have permission" : ""}
    >
      <div key={group.name}>
        <Item
          hasPermission={hasPermission}
          icon={`fa-regular fa-${collapsed ? "folder" : "folder-open"}`}
          style={{ color: colorTheme("neutralL1") }}
          text={group.name}
          paddingLeft={gridSpacing[6]}
          path={groupPath}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
        >
          {hasPermission && !isLoading && (
            <StatusPill
              small
              type={
                groupPath === window.location.pathname ? "primary" : "default"
              }
              text={insightsDashboards.length.toString()}
              style={{ marginLeft: gridSpacing[2], height: 20 }}
            />
          )}
        </Item>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!collapsed && (
            <>
              {insightsDashboards.map((dashboard) => (
                <DashboardItem
                  key={dashboard.name}
                  dashboard={dashboard}
                  groupPath={group.path}
                  subjectPath={subjectPath}
                  selectedObject={selectedObject}
                  setSelectedObject={setSelectedObject}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </ToolTip>
  );
};

export default GroupItem;
