import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { colorTheme } from "@utils";

const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  user-select: none;
  input:checked ~ span {
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor || colorTheme("primary")};
    border: 1px solid
      ${({ $backgroundColor }) => $backgroundColor || colorTheme("primary")};
  }
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    left: ${({ $small }) => ($small ? "4px" : "5px")};
    top: ${({ $small }) => ($small ? "2px" : "1px")};
    width: ${({ $small }) => ($small ? "3px" : "5px")};
    height: ${({ $small }) => ($small ? "6px" : "9px")};
    border: ${({ $checkMarkColor }) =>
      `solid ${$checkMarkColor || colorTheme("white")}`};
    border-width: ${({ $small }) => ($small ? "0 2px 2px 0" : "0 3px 3px 0")};
    transform: rotate(45deg);
  }
`;

const StyledHiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledCheckbox = styled.span`
  position: absolute;
  left: 0;
  height: ${({ $small }) => ($small ? "14px" : "18px")};
  width: ${({ $small }) => ($small ? "14px" : "18px")};
  border: 1px solid ${colorTheme("neutralL2")};
  border-radius: 3px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

function Checkbox({
  resolvedRef,
  rest,
  small,
  backgroundColor,
  checkMarkColor,
  checked,
  onChange = () => {},
  controlled = true,
}) {
  return (
    <StyledCheckboxLabel
      $backgroundColor={backgroundColor}
      $checkMarkColor={checkMarkColor}
      $small={small}
    >
      {controlled ? (
        <StyledHiddenInput
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
      ) : (
        <StyledHiddenInput
          type="checkbox"
          ref={resolvedRef}
          onChange={onChange}
          {...rest}
        />
      )}
      <StyledCheckbox $small={small} />
    </StyledCheckboxLabel>
  );
}

Checkbox.propTypes = {
  resolvedRef: PropTypes.object,
  rest: PropTypes.object,
  small: PropTypes.bool,
  backgroundColor: PropTypes.string,
  checkMarkColor: PropTypes.string,
  checked: PropTypes.bool,
  controlled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
