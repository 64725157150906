import React, { useState, useEffect, Fragment, useId } from "react";

import debounce from "debounce";
import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useRowSelect,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
} from "react-table";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";

import { colorTheme } from "@utils";
import { gridSpacing } from "@utils";

import {
  SearchText,
  Select,
  Button,
  Actions,
  DropDown,
  Checkbox,
  ToolTip,
} from "../";
import { numberWithCommas } from "../utilities";

const TableMainHeader = styled.div`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const TableHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: ${colorTheme("neutralL1")};
  text-transform: uppercase;
  background: ${colorTheme("neutralL6")};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  .table-data {
    background: ${colorTheme("neutralL6")};
  }
`;

const TableRow = styled.div`
  display: flex;

  .table-data {
    display: flex;
    align-items: center;
    border-bottom: ${(props) =>
      props.$isExpanded ? "none" : `1px solid ${colorTheme("neutralL4")}`};
    background: ${colorTheme("white")};
  }

  &:hover {
    .table-data {
      background: ${colorTheme("neutralL5")};
    }
  }
`;

const TableData = styled.div`
  margin: 0;
  padding: 10px;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${({ $isHeader }) => $isHeader && `background: ${colorTheme("neutralL6")}`};
`;

const TableDataSelection = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 30px;
`;

const TableDataBlankSelection = styled.div`
  margin: 0;
  padding: 10px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 5 0 auto;
  width: 30px;
`;

const Resizer = styled.div`
  border-left: 3px dashed transparent;
  right: 0;
  width: 4px;
  height: 80%;
  position: absolute;
  top: 10%;
  touch-action: none;

  &:hover {
    border-color: ${colorTheme("neutralL2")};
  }
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 15px;
  color: ${colorTheme("neutral")};
  font-size: 14px;
`;

const PaginationIcon = styled.i`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid ${colorTheme("neutralL3")};
  color: ${(props) =>
    props.disabled ? colorTheme("neutralL3") : colorTheme("neutral")};
  width: 14px;

  &:hover {
    color: ${(props) =>
      props.disabled ? colorTheme("neutralL3") : colorTheme("neutralL1")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

const Styles = styled.div`
  border-radius: 5px;
  background: ${colorTheme("white")};
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <Checkbox controlled={false} resolvedRef={resolvedRef} rest={rest} />
    );
  },
);

/**
 * Table?
 * @param {any} props
 * @returns {any}
 */
function Table(props) {
  let {
    columns,
    hiddenColumns,
    data,
    filterValue,
    filterOnChange,
    setPageSize,
    defaultSorted,
    sortOnChange,
    currentPage,
    totalCount,
    setCurrentPage,
    buttonText,
    buttonIcon,
    buttonOnClick,
    selectionMenuItems,
    hidePagination,
    hideTableHeader,
    noScroll,
    headerText,
    inlineActions,
    actions,
    checkboxOmitRules,
    renderRowSubComponent,
    rowKey,
    selectedRows,
    setSelectedRows,
    isLoading,
    expandedRows,
    setExpandedRows,
    apiSidedData,
    exportAction,
    selectAllDisabled,
    cellStyle,
    headerStyle,
    cellFallback,
  } = props;

  const [tableWrapperId, _setTableWrapperId] = useState(
    `tableWrapper-${useId()}`,
  );

  const [renderedRows, setRenderedRows] = useState([]);
  const [pageSize, setLocalPageSize] = useState(
    (hidePagination ? data.length : props.pageSize) || 25,
  );
  const [pageIndex, setPageIndex] = useState(currentPage || 0);
  const [parentWidth, setParentWidth] = useState(500);
  const [selectedOpen, setSelectedOpen] = useState(false);
  const [unselectableRows, setUnselectableRows] = useState([]);
  const [finalSelectedRows, setFinalSelectedRows] = useState([]);
  const [rowBodyScrollWidth, setRowBodyScrollWidth] = useState(1000);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { sortBy, expanded },
  } = useTable(
    {
      initialState: {
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortBy: defaultSorted ? defaultSorted : [],
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
        expanded: expandedRows || {},
      },
      manualSortBy: apiSidedData,
      columns,
      data,
      manualPagination: true,
      autoResetSortBy: false,
      // Sort case insensitive https://stackoverflow.com/questions/51568969/how-to-add-case-insensitive-sorting-in-react-table
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (Array.isArray(rowOneColumn) && Array.isArray(rowTwoColumn)) {
            return rowOneColumn.length > rowTwoColumn.length ? 1 : -1;
          } else if (
            typeof rowOneColumn === "string" &&
            typeof rowTwoColumn === "string"
          ) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
              ? 1
              : -1;
          } else if (
            typeof rowOneColumn === "number" &&
            typeof rowTwoColumn === "number"
          ) {
            return rowOneColumn > rowTwoColumn ? 1 : -1;
          } else {
            return 0;
          }
        },
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      selectionMenuItems &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            maxWidth: 30,
            minWidth: 30,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => {
              let selectedProps = getToggleAllRowsSelectedProps();
              if (rowKey && Array.isArray(selectedRows) && setSelectedRows) {
                selectedProps = {
                  checked:
                    selectedRows.length === data.length &&
                    selectedRows.length !== 0,
                  onClick: () => {
                    const newSelectedRows =
                      selectedRows.length === data.length
                        ? []
                        : data.map((row) => row[rowKey]);
                    setSelectedRows(newSelectedRows);
                  },
                };
              }
              return selectAllDisabled ? (
                <div style={{ width: 16 }} />
              ) : (
                <IndeterminateCheckbox {...selectedProps} />
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              let selectedProps = row.getToggleRowSelectedProps();
              // Directly control checkboxes if selectedRows is an array
              if (rowKey && Array.isArray(selectedRows) && setSelectedRows) {
                selectedProps = {
                  checked: selectedRows.includes(row.original[rowKey]),
                  onClick: () => {
                    const id = row.original[rowKey];
                    const newSelectedRows = selectedRows.includes(id)
                      ? selectedRows.filter((rowId) => rowId !== id)
                      : [...selectedRows, id];
                    setSelectedRows(newSelectedRows);
                  },
                };
              }
              return <IndeterminateCheckbox {...selectedProps} />;
            },
          },
          ...columns,
        ]);
    },
    useResizeColumns,
    useFlexLayout,
    useSticky,
  );

  const resetParentWidth = debounce((e) => {
    setParentWidth(e);
  }, 15);

  // Create listener to parent node width
  useEffect(() => {
    const tableWrapper = document.getElementById(tableWrapperId);
    if (tableWrapper) {
      let resizeObserver = new ResizeObserver((e) => {
        resetParentWidth(e[0].contentRect.width);
      });

      resizeObserver.observe(tableWrapper.parentNode);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [document.getElementById(tableWrapperId)]);

  useEffect(() => {
    const rows = selectedFlatRows.filter(
      (row) => !unselectableRows.includes(row) && renderedRows.includes(row),
    );
    setFinalSelectedRows(rows);
    // Only call setSelectedRows if table is not controlled
    !Array.isArray(selectedRows) && setSelectedRows && setSelectedRows(rows);
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (sortOnChange) sortOnChange(sortBy);
  }, [sortBy]);

  const getLowIndex = () => {
    if (pageIndex === 0) {
      if (data.length === 0) {
        return 0;
      }
      return 1;
    } else {
      let lowIndex = pageIndex * pageSize;
      if (lowIndex < 1) {
        return pageSize;
      } else {
        while (lowIndex > data.length) {
          lowIndex -= pageSize;
        }

        return lowIndex;
      }
    }
  };

  useEffect(() => {
    if (hidePagination) {
      setLocalPageSize(data.length);
    }
  }, [data]);

  useEffect(() => {
    const isUnSelectableRow = (row) =>
      Object.entries(checkboxOmitRules).some(([columnName, rules]) =>
        rules.includes(row.original[columnName]),
      );

    if (finalSelectedRows?.length > 0) {
      toggleAllRowsSelected(false);
      setFinalSelectedRows([]);
      // Only call setSelectedRows if table is not controlled
      !Array.isArray(selectedRows) && setSelectedRows && setSelectedRows([]);
    }

    if (checkboxOmitRules) {
      const _unselectableRows = rows.filter((row) => isUnSelectableRow(row));
      setUnselectableRows(_unselectableRows);
    }

    let highIndex = 0;
    let lowIndex = getLowIndex();
    if (lowIndex === 1) {
      lowIndex = -1;
      highIndex = pageSize;
    } else {
      highIndex = lowIndex + pageSize;
    }
    if (totalCount) {
      setRenderedRows(rows);
    } else {
      setRenderedRows(rows.filter((_, i) => i + 1 > lowIndex && i < highIndex));
    }
  }, [pageIndex, data, pageSize, sortBy]);

  useEffect(() => {
    if (pageIndex !== 0) {
      setPageIndex(0);
    }
  }, [pageSize]);

  useEffect(() => {
    if (setCurrentPage && currentPage !== pageIndex) {
      setCurrentPage(pageIndex);
    }
  }, [pageIndex]);

  // This used effect is to ensure that page index and currentPage (passed in by user) are in sync
  useEffect(() => {
    if (typeof currentPage === "number" && currentPage !== pageIndex) {
      setPageIndex(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (props.pageSize) {
      if (hidePagination) {
        setLocalPageSize(data.length);
      } else {
        setLocalPageSize(props.pageSize);
      }
    }
  }, [props.pageSize]);

  useEffect(() => {
    setExpandedRows && setExpandedRows(expanded);
  }, [expanded]);

  useEffect(() => {
    const tableWrapper = document.getElementById(tableWrapperId);
    const tableWidth = tableWrapper.getBoundingClientRect().width;
    const rowsBody = document.getElementById(`${tableWrapperId}-rows`);

    if (
      tableWidth !== parentWidth ||
      tableWrapper.scrollWidth !== parentWidth
    ) {
      tableWrapper.style.width = `${parentWidth}px`;
      setRowBodyScrollWidth(noScroll ? parentWidth : rowsBody.scrollWidth - 15);
    } else {
      tableWrapper.style.width = "";
      setRowBodyScrollWidth("");
    }
  }, [parentWidth]);

  const getPaginationAmount = () => {
    let pageAmt = (pageIndex + 1) * pageSize;

    if (pageAmt > data.length && !totalCount) {
      return data.length;
    } else if (totalCount < pageAmt) {
      return totalCount;
    } else {
      return pageAmt;
    }
  };

  const getLowerPaginationAmount = () => {
    let pageAmt = getPaginationAmount() - pageSize;
    if (pageAmt < 1) return 1;
    return pageAmt;
  };

  const cellProps = (props, { cell }) => {
    let propArray = [props];
    let style = cell.column?.style || {};

    if (cell.column.overflow) {
      style.overflow = "visible";
    }

    propArray.push({ style });

    return propArray;
  };

  const buttonExists = buttonText || buttonIcon;

  const renderEmptyState = () => {
    if (isLoading) {
      return (
        <p style={{ textAlign: "center", padding: 15 }}>Loading data...</p>
      );
    } else if (renderedRows.length === 0) {
      return (
        <p style={{ textAlign: "center", padding: 15 }}>No data available</p>
      );
    } else {
      return null;
    }
  };

  const determineRowExpanded = (row) => {
    if (expandedRows) {
      return Object.keys(expandedRows).includes(row.id);
    }
    return row.isExpanded;
  };

  const lowerPaginationAmount = getLowerPaginationAmount();
  const paginationAmount = getPaginationAmount();

  const renderCell = (cell) => {
    if (!cellFallback || !!cell.value) {
      return cell.render("Cell");
    } else {
      return "-";
    }
  };

  // Render the UI for your table
  return (
    <Styles style={props.style} role="table">
      {exportAction && !isLoading && (
        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              right: gridSpacing[2],
              top: -gridSpacing[4],
              display: "flex",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <ToolTip text="Export">
              <Button
                small
                icon="fa-download"
                type="link"
                onClick={exportAction}
              />
            </ToolTip>
          </div>
        </div>
      )}
      {headerText && <TableMainHeader>{headerText}</TableMainHeader>}
      <Actions inlineActions={inlineActions}>
        {buttonExists && (
          <Button icon={buttonIcon} text={buttonText} onClick={buttonOnClick} />
        )}
        {actions}
        <Actions.Filters>
          {filterOnChange && (
            <SearchText value={filterValue} onChange={filterOnChange} />
          )}
        </Actions.Filters>
      </Actions>
      {
        // Row Selection Button
        selectionMenuItems && selectionMenuItems.length !== 0 && (
          <DropDown direction="down">
            <DropDown.Toggle>
              <Button
                mb={3}
                text={`${finalSelectedRows.length} selected`}
                icon="fa-solid fa-chevron-down"
                iconPos="right"
                iconStyle={{ marginLeft: 5 }}
                disabled={finalSelectedRows.length === 0}
                onClick={() => setSelectedOpen(!selectedOpen)}
                type="secondary"
              />
            </DropDown.Toggle>
            <DropDown.Menu>
              {selectionMenuItems
                .filter((menuItem) => menuItem.condition(finalSelectedRows))
                .map((menuItem, index) => (
                  <DropDown.Item
                    key={index}
                    onClick={() => menuItem.func(finalSelectedRows)}
                  >
                    {menuItem.title}
                  </DropDown.Item>
                ))}
            </DropDown.Menu>
          </DropDown>
        )
      }

      <TableWrapper style={props.tableStyle}>
        <div
          {...getTableProps()}
          className="table"
          id={tableWrapperId}
          role={undefined}
        >
          {!hideTableHeader && (
            <TableHeader style={{ width: rowBodyScrollWidth }}>
              <div
                style={{ display: "flex", flexGrow: 1, alignItems: "center" }}
              >
                {headerGroups.map((headerGroup) => {
                  const headerGroupProps = headerGroup.getHeaderGroupProps();
                  return (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      {...headerGroupProps}
                      key={headerGroupProps.key}
                    >
                      {headerGroup.headers.map((column) => {
                        const columnHeaderProps = column.getHeaderProps();

                        return column.id == "selection" ? (
                          <TableDataSelection
                            key={columnHeaderProps.key}
                            role="columnheader"
                          >
                            <div>
                              <span {...column.getSortByToggleProps()} title="">
                                {column.render("Header")}
                              </span>
                            </div>
                          </TableDataSelection>
                        ) : (
                          <TableData
                            $isHeader
                            key={columnHeaderProps.key}
                            role="columnheader"
                            style={{
                              ...(columnHeaderProps?.["style"] ?? {}),
                              ...(headerStyle ?? {}),
                            }}
                          >
                            <div>
                              <span {...column.getSortByToggleProps()} title="">
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i
                                      className="fa-solid fa-arrow-down"
                                      style={{ paddingLeft: 5 }}
                                    />
                                  ) : (
                                    <i
                                      className="fa-solid fa-arrow-up"
                                      style={{ paddingLeft: 5 }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                              {column.canResize && (
                                <Resizer {...column.getResizerProps()} />
                              )}
                            </div>
                          </TableData>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </TableHeader>
          )}
          <div {...getTableBodyProps()} id={`${tableWrapperId}-rows`}>
            {!isLoading &&
              renderedRows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <Fragment key={`fragment-${i}`}>
                    <TableRow
                      $isExpanded={determineRowExpanded(row)}
                      key={rowProps.key}
                      role="row"
                      style={{ width: rowBodyScrollWidth }}
                    >
                      {row.cells.map((cell, j) => {
                        let omit = unselectableRows.includes(row);
                        const _cellProps = cell.getCellProps(cellProps);

                        return omit && j === 0 ? (
                          <TableDataBlankSelection
                            key={_cellProps.key}
                            className="table-data"
                          />
                        ) : selectionMenuItems && j === 0 ? (
                          <TableDataSelection
                            key={_cellProps.key}
                            className="table-data"
                          >
                            {renderCell(cell)}
                          </TableDataSelection>
                        ) : (
                          <TableData
                            key={_cellProps.key}
                            role="cell"
                            className="table-data"
                            style={{
                              ...(_cellProps?.["style"] ?? {}),
                              ...(cellStyle ?? {}),
                            }}
                          >
                            {renderCell(cell)}
                          </TableData>
                        );
                      })}
                    </TableRow>
                    {determineRowExpanded(row) && (
                      <TableRow key={`expanded-${i}`}>
                        <TableData
                          colSpan={columns.length}
                          className="table-data"
                          style={cellStyle}
                        >
                          {renderRowSubComponent({ row })}
                        </TableData>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            {renderEmptyState()}
          </div>
        </div>
      </TableWrapper>

      {!hidePagination && (
        <Pagination>
          Rows
          <div
            style={{
              width: 87,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Select
              value={pageSize}
              onChange={(e) => {
                if (setPageSize) {
                  setPageSize(Number(e));
                } else {
                  setLocalPageSize(Number(e));
                }
              }}
              options={[5, 10, 15, 25, 50, 100].map((pageSizeOption) => {
                return {
                  label: pageSizeOption + "",
                  value: pageSizeOption,
                };
              })}
              hideSearch
              style={{ paddingBottom: 0 }}
              disabled={isLoading}
            />
          </div>
          <span style={{ marginRight: 10 }}>
            {numberWithCommas(lowerPaginationAmount)} -{" "}
            {numberWithCommas(paginationAmount)} of{" "}
            {totalCount
              ? numberWithCommas(totalCount)
              : numberWithCommas(data.length)}
          </span>
          <PaginationIcon
            style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
            className="fa-regular fa-chevron-left"
            onClick={() => {
              if (pageIndex > 0) {
                setPageIndex(pageIndex - 1);
              }
            }}
            disabled={isLoading || pageIndex === 0}
          />
          <PaginationIcon
            style={{
              borderLeft: 0,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
            }}
            className="fa-regular fa-chevron-right"
            onClick={() => {
              if (
                (!totalCount && paginationAmount !== data.length) ||
                (totalCount && paginationAmount !== totalCount)
              ) {
                setPageIndex(pageIndex + 1);
              }
            }}
            disabled={
              isLoading ||
              (!totalCount && paginationAmount === data.length) ||
              (totalCount && paginationAmount === totalCount)
            }
          />
        </Pagination>
      )}
    </Styles>
  );
}

Table.propTypes = {
  /** Styled object for table container */
  style: PropTypes.object,
  /** Styled object for table wrapper */
  tableStyle: PropTypes.object,
  /** Columns array */
  columns: PropTypes.array,
  /** Array of strings of columns not to hide on table render */
  hiddenColumns: PropTypes.array,
  /** Array of objects to be displayed in the table */
  data: PropTypes.array,
  /** Value of filter bar */
  filterValue: PropTypes.string,
  /** Callback for when the filter bar value changes */
  filterOnChange: PropTypes.func,
  /** Default sorted by ID, array of objects  defaultSorted={[
   {
   id: 'id',
   },
   ]} */
  defaultSorted: PropTypes.array,
  /** Callback for when the sort value changes returns id_boolean (boolean being desc) */
  sortOnChange: PropTypes.func,
  /** Sets the amount of rows the table will display per page */
  pageSize: PropTypes.number,
  /** Callback that sends a parameter for the number of rows per page */
  setPageSize: PropTypes.func,
  /** Server side pagination: The current page the table is on */
  currentPage: PropTypes.number,
  /** Server side pagination: The total amount of data items in the table */
  totalCount: PropTypes.number,
  /** Server side pagination: Callback when the current page is changed */
  setCurrentPage: PropTypes.func,
  /** Text of the button that will be displayed to the left of table */
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Font awesome class for icon of button */
  buttonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Callback when the button is clicked */
  buttonOnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /**
   Array of object definitons for the selection button:
   [{
   title: <str>,
   func: <func>
   },...]
   The existence of this prop also determines if row selection should be turned on.
   */
  selectionMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      func: PropTypes.func.isRequired,
      condition: PropTypes.func.isRequired,
    }),
  ),
  /** Show or don't show pagination */
  hidePagination: PropTypes.bool,
  hideTableHeader: PropTypes.bool,
  headerText: PropTypes.string,
  /** Keep filter and actions inline */
  inlineActions: PropTypes.bool,
  /** An object with column_name keys and an array values that disable that row from being selected
   * {
   *    column_name: ["cell_contents_that_make_this_row_unselectable", "other_bad_contents"]
   * }
   */
  checkboxOmitRules: PropTypes.object,
  renderRowSubComponent: PropTypes.func,
  actions: PropTypes.object,
  rowKey: PropTypes.string,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  isLoading: PropTypes.bool,
  maxBodyHeight: PropTypes.number,
  setExpandedRows: PropTypes.func,
  expandedRows: PropTypes.array,
  /** If the data is coming from an API, disable client sided sorting */
  apiSidedData: PropTypes.bool,
  exportAction: PropTypes.func,
  noScroll: PropTypes.bool, // Disables scrolling on overflow rows
  selectAllDisabled: PropTypes.bool,
  cellStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  cellFallback: PropTypes.string,
};

export default Table;
