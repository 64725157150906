import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { colorTheme } from "@utils";
import { gridSpacing } from "@utils";

import { Divider, TableLink } from "../";

const Container = styled.div`
  margin-bottom: ${({ $readMoreOnClick }) =>
    `${$readMoreOnClick ? gridSpacing[6] : gridSpacing[4]}px`};
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gridSpacing[4]}px;
`;

const Title = styled.b`
  font-size: 12px;
  color: ${colorTheme("neutralL1")};
`;

const Content = styled.p`
  color: ${colorTheme("neutralL1")};
  max-width: 100%;
  max-height: 75px;
  margin-right: ${gridSpacing[2]}px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ObjectDetail = ({
  style,
  readMoreOnClick,
  data,
  includeDivider,
  testId,
}) => (
  <>
    <Container $readMoreOnClick={!!readMoreOnClick} style={style}>
      {data.map((dataRow, i) => (
        <div key={i} style={{ display: "flex" }}>
          {dataRow
            .filter((dataObj) => dataObj.title || dataObj.content)
            .map((dataObj, i) => (
              <DetailContainer
                style={{ width: `${100 / dataRow.length}%` }}
                key={i}
                data-testid={`${testId}-${dataObj.title}`}
              >
                {dataObj.title && <Title>{dataObj.title}</Title>}

                <Content>{dataObj.content ? dataObj.content : "-"}</Content>
              </DetailContainer>
            ))}
        </div>
      ))}
      {readMoreOnClick && (
        <span style={{ display: "inline-block" }}>
          <TableLink style={{ fontSize: 14 }} onClick={readMoreOnClick}>
            More details
          </TableLink>
        </span>
      )}
    </Container>
    {includeDivider && (
      <Divider style={{ marginTop: 0, marginBottom: gridSpacing[6] }} />
    )}
  </>
);

ObjectDetail.propTypes = {
  style: PropTypes.object,
  data: PropTypes.array,
  readMoreOnClick: PropTypes.func,
  includeDivider: PropTypes.object,
  testId: PropTypes.string,
};

export default ObjectDetail;
