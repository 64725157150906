import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { colorTheme } from "@utils";

const Container = styled.p`
  text-decoration: underline;
  font-style: italic;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("primary")};
  }
`;

const HyperLink = ({ onClick, children, style, testId }) => (
  <Container onClick={onClick} style={style} data-testid={testId}>
    {children}
  </Container>
);
HyperLink.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  style: PropTypes.object,
  /** data-testid attribute */
  testId: PropTypes.string,
};

export default HyperLink;
