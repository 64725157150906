import React from "react";

import { toast, Slide } from "react-toastify";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

import { TableLink } from "../LucernaHealthUIComponents";

type ToastType = "success" | "error" | "default" | "warning";

type TypeProps = Record<
  ToastType,
  {
    icon: string;
    color: string;
    hoverColor: string;
  }
>;

interface ToastProps {
  type: "success" | "error" | "default" | "warning";
  message: string;
  closeOnClick?: boolean;
  autoClose?: undefined | boolean;
  autoCloseSeconds?: undefined | number;
  link?: {
    onClick?: () => void;
    text: string;
    url?: string;
  };
}

const Icon = styled.i`
  font-size: 18px;
  margin-right: ${gridSpacing[2]}px;
`;

const StyledTableLink = styled(TableLink)<{
  color: string;
  hoverColor: string;
}>`
  pointer-events: none; // Disable pointer events on the link itself

  margin-left: 27px; // Line up with start of message
  font-weight: bold;
  color: ${({ color }) => color} !important;
  text-decoration: underline;
  font-size: 14px;
  margin-top: ${gridSpacing[1]}px;

  &:hover {
    color: ${({ hoverColor }) => hoverColor} !important;
  }

  span {
    pointer-events: auto; // Enable pointer events on the span
    display: inline;
  }
`;

export const Toast = ({
  type,
  message,
  closeOnClick = true,
  autoClose,
  autoCloseSeconds,
  link,
}: ToastProps) => {
  const typeProps: TypeProps = {
    success: {
      icon: "fa-circle-check",
      color: colorTheme("success"),
      hoverColor: colorTheme("successD1"),
    },
    error: {
      icon: "fa-circle-xmark",
      color: colorTheme("danger"),
      hoverColor: colorTheme("dangerD1"),
    },
    default: {
      icon: "fa-circle-info",
      color: colorTheme("info"),
      hoverColor: colorTheme("infoD1"),
    },
    warning: {
      icon: "fa-triangle-exclamation",
      color: colorTheme("warning"),
      hoverColor: colorTheme("warningD1"),
    },
  };

  const RenderMessage = () => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon className={`fa-solid ${typeProps[type].icon}`} />
        {message}
      </div>
      {(link?.onClick || link?.url) && (
        <StyledTableLink
          newTab
          color={typeProps[type].color}
          hoverColor={typeProps[type].hoverColor}
          onClick={link?.onClick}
          url={link?.url}
        >
          <span>{link.text}</span>
        </StyledTableLink>
      )}
    </div>
  );

  const getAutoClose = () => {
    if (autoClose === false || (type === "error" && autoClose !== true)) {
      return false;
    }

    if (link && autoCloseSeconds === undefined) {
      return 6000;
    }

    if (autoCloseSeconds) {
      return autoCloseSeconds * 1000;
    }

    return 3000;
  };

  toast(RenderMessage(), {
    type,
    closeOnClick,
    theme: "colored",
    autoClose: getAutoClose(),
    transition: Slide,
    icon: false,
  });
};
