import React from "react";

import type { BreadCrumbsProps } from "./BreadCrumbs";
import gridSpacing from "../../../../../utils/gridSpacing";
import GroupLabel from "../../GroupLabel";
import Option from "../../Option";
import SelectAll from "../SelectAll";

const BreadCrumbsGroups = (props: BreadCrumbsProps) => {
  const propsValue = Array.isArray(props.value) ? props.value : [props.value];

  const valueCount = propsValue?.filter((allValue) =>
    props.options?.some((o) => allValue === o.value),
  ).length;

  const id = (props?.groupValue || props?.label) ?? "";

  const isOpened = props.openedGroups.some((group: string) => {
    // Confirm that the group is using dot notation
    const splitGroup = group.includes(".") ? group.split(".") : undefined;

    return group === id || (splitGroup && splitGroup.includes(id));
  });

  let root = (
    <div onClick={() => props.setOpenedGroups([id])}>
      <GroupLabel
        {...props}
        opened
        popup
        options={props.options}
        valueCount={valueCount}
      />
    </div>
  );

  if (
    (props.options === null || props.options === undefined) &&
    !props.customOptions
  ) {
    const rootSelected = props.value?.includes(`${props.groupValue}`);
    root = (
      <Option
        label={props.label}
        checkbox={rootSelected}
        isSelected={rootSelected}
        onClick={() =>
          props.selectOption(
            { label: props.label, value: props.groupValue },
            "",
          )
        }
      />
    );
  }

  if (!isOpened && props.openedGroups.length === 0) {
    return root;
  }

  if (isOpened) {
    const openedGroupId = props.openedGroups[0];
    const openGroupArray = openedGroupId.split(".");
    const breadCrumbDepth = openGroupArray.length;

    let options = props.options;
    let customOptions = props.customOptions;
    if (breadCrumbDepth > 1) {
      for (let i = 1; i < breadCrumbDepth; i++) {
        const foundOption = options?.find(
          ({ value, label }) =>
            value === openGroupArray[i] || label === openGroupArray[i],
        );

        options = foundOption?.options || [];

        if (foundOption?.customOptions) {
          customOptions = foundOption.customOptions;
        }
      }
    }

    if (customOptions) {
      return <div style={{ padding: gridSpacing[3] }}>{customOptions}</div>;
    }

    const optionsThatCanBeSelected =
      options?.filter((o) => !o.options && !o.customOptions) ?? [];

    return (
      <>
        <SelectAll
          visible={optionsThatCanBeSelected.length > 0 && props.showSelectAll}
          selectAll={() =>
            props.selectMultiple(
              optionsThatCanBeSelected ?? [],
              props.openedGroups[0],
            )
          }
          deselectAll={() =>
            props.deselectMultiple(
              optionsThatCanBeSelected ?? [],
              props.openedGroups[0],
            )
          }
          type="breadcrumbs"
        />
        {options?.length === 0 && (
          <div style={{ padding: gridSpacing[3] }}>No options</div>
        )}
        {options?.map((optionProps) => {
          const { label, value } = optionProps;

          const _options = optionProps.options;
          if (_options || optionProps.customOptions) {
            let count;
            if (_options) {
              count = propsValue?.filter((allValue) =>
                _options.some((o) => allValue === o.value),
              ).length;
            }

            return (
              <div
                key={value}
                onClick={() =>
                  props.setOpenedGroups([openedGroupId + "." + value])
                }
              >
                <GroupLabel
                  popup
                  label={`${label}`}
                  options={optionProps.options}
                  valueCount={count}
                />
              </div>
            );
          }

          return (
            <Option
              checkbox
              key={value}
              data={optionProps}
              isSelected={props.value?.includes(`${value}`)}
              disabled={false}
              onClick={() =>
                props.selectOption({ label, value }, props.openedGroups[0])
              }
            />
          );
        })}
      </>
    );
  }

  return null;
};

export default BreadCrumbsGroups;
