import React from "react";

import { updateUserMeta } from "@actions";
import { Button, ButtonGroup, SearchText, Select } from "@commonComponents";
import { connect } from "react-redux";
import { styled } from "styled-components";

import { gridSpacing } from "@utils";
import { capitalizeOnlyFirstLetter, colorTheme } from "@utils";

import type { FilterProps } from "./helpers";
import { checkGroupPermission } from "../helpers";
import FilterButton from "~/src/CommonComponents/AdvancedFiltersV2/FilterButton";
import type { ListAnalyticDomain } from "~/src/types";

interface HeaderProps {
  includeFilters?: boolean;
  filters: FilterProps;
  setFilters: (filters: FilterProps) => void;
  updateUserMeta: (key: string, value: boolean | string) => void;
  tableView: boolean;
  setTableView: (value: boolean) => void;
  subjects: ListAnalyticDomain[];
  tab?: string;
  handleExpandAll?: () => void;
  handleCollapseAll?: () => void;
  groupBy?: string;
  setGroupBy?: (value: string) => void;
  customerName: string;
}

const StyledHeader = styled.div<{ $tab?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${colorTheme("white")};
  margin-bottom: ${({ $tab }) =>
    $tab !== "all-published-dashboards" ? gridSpacing[4] : 0}px;
`;

const Header = ({
  includeFilters = true,
  filters,
  setFilters,
  tableView,
  setTableView,
  updateUserMeta,
  subjects,
  tab,
  handleExpandAll,
  handleCollapseAll,
  groupBy,
  setGroupBy,
  customerName,
}: HeaderProps) => {
  const getSubdomainOptions = (filters: FilterProps) => {
    const foundSubject = subjects.find(
      (subject) => subject.name === filters.analytic_domain,
    );

    if (foundSubject) {
      return foundSubject.subdomains
        .filter((subdomain) =>
          checkGroupPermission(foundSubject.path || "", subdomain.path),
        )
        .map((group) => {
          return {
            label: group.name,
            value: group.name,
          };
        });
    }

    return subjects.flatMap((subject) =>
      subject.subdomains
        .filter((subdomain) =>
          checkGroupPermission(subject.path, subdomain.path),
        )
        .map((subdomain) => {
          return {
            label: subdomain.name,
            value: subdomain.name,
          };
        }),
    );
  };

  return (
    <div>
      <StyledHeader $tab={tab}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: gridSpacing[3],
            }}
          >
            <SearchText
              fixedWidth
              value={filters.search}
              onChange={(e) =>
                setFilters({ ...filters, search: e.target.value })
              }
            />
            <div style={{ display: "flex" }}>
              {groupBy && setGroupBy && (
                <ButtonGroup
                  small
                  value={groupBy}
                  onChange={(value) => {
                    setGroupBy(value as string);
                  }}
                  style={{
                    marginRight: gridSpacing[2],
                  }}
                >
                  <Button
                    text="Ungroup"
                    onClick={() => {
                      setGroupBy("ungroup");
                      updateUserMeta("leapInsightsGroupBy", "ungroup");
                    }}
                    tooltip="Ungroup"
                    value="ungroup"
                  />
                  <Button
                    text="Domain"
                    onClick={() => {
                      setGroupBy("analyticDomain");
                      updateUserMeta("leapInsightsGroupBy", "analyticDomain");
                    }}
                    tooltip="Group by domain"
                    value="analyticDomain"
                  />
                  <Button
                    text="Subdomain"
                    onClick={() => {
                      setGroupBy("subdomain");
                      updateUserMeta("leapInsightsGroupBy", "subdomain");
                    }}
                    tooltip="Group by subdomain"
                    value="subdomain"
                  />
                </ButtonGroup>
              )}
              <ButtonGroup
                small
                value={tableView}
                onChange={(value) => {
                  if (value) {
                    setTableView(true);
                    updateUserMeta("leapInsightsTableView", true);
                  } else {
                    setTableView(false);
                    updateUserMeta("leapInsightsTableView", false);
                  }
                }}
              >
                <Button
                  icon="fa-grid-2"
                  type="toggle"
                  value={false}
                  tooltip="Card View"
                />
                <Button
                  value
                  icon="fa-list"
                  type="toggle"
                  tooltip="Table View"
                />
              </ButtonGroup>
            </div>
          </div>

          {includeFilters && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 34, // Match height of buttons even when buttons are hidden
              }}
            >
              <div style={{ display: "flex" }}>
                <Select
                  value={filters.type}
                  options={[
                    {
                      label: "Custom",
                      value: "custom",
                    },
                    {
                      label: "Standard",
                      value: "standard",
                    },
                  ]}
                  onChange={(e) => setFilters({ ...filters, type: e })}
                  customComponent={
                    <FilterButton
                      label="Dashboard Type"
                      values={
                        filters.type
                          ? [capitalizeOnlyFirstLetter(filters.type)]
                          : undefined
                      }
                      clearValues={() => setFilters({ ...filters, type: "" })}
                    />
                  }
                />
                <Select
                  ml={2}
                  value={filters.managed_by}
                  options={[
                    {
                      label: customerName,
                      value: "customer",
                    },
                    {
                      label: "Lucerna",
                      value: "lucerna",
                    },
                  ]}
                  onChange={(e) => setFilters({ ...filters, managed_by: e })}
                  customComponent={
                    <FilterButton
                      label="Managed By"
                      values={
                        filters.managed_by === "customer"
                          ? [customerName]
                          : filters.managed_by
                            ? [capitalizeOnlyFirstLetter(filters.managed_by)]
                            : undefined
                      }
                      clearValues={() =>
                        setFilters({ ...filters, managed_by: "" })
                      }
                    />
                  }
                />
                <Select
                  ml={2}
                  value={filters.analytic_domain}
                  options={subjects
                    .filter(
                      (subject) =>
                        subject.subdomains.filter((subdomain) =>
                          checkGroupPermission(subject.path, subdomain.path),
                        ).length > 0,
                    )
                    .map((subject) => {
                      return {
                        label: subject.name,
                        value: subject.name,
                      };
                    })}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      analytic_domain: e,
                      subdomain: [],
                    });
                  }}
                  customComponent={
                    <FilterButton
                      label="Analytic Domain"
                      values={
                        filters.analytic_domain
                          ? [filters.analytic_domain]
                          : undefined
                      }
                      clearValues={() =>
                        setFilters({ ...filters, analytic_domain: "" })
                      }
                    />
                  }
                />
                <Select
                  isMulti
                  ml={2}
                  value={filters.subdomain}
                  options={getSubdomainOptions(filters)}
                  onChange={(e) => setFilters({ ...filters, subdomain: e })}
                  customComponent={
                    <FilterButton
                      label="Subdomain"
                      values={filters.subdomain}
                      clearValues={() =>
                        setFilters({ ...filters, subdomain: [] })
                      }
                    />
                  }
                />
              </div>
              {tab === "all-published-dashboards" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: colorTheme("neutralL1"),
                  }}
                >
                  {groupBy !== "ungroup" && (
                    <ButtonGroup small>
                      <Button
                        icon="fa-maximize"
                        onClick={handleExpandAll}
                        tooltip="Expand All"
                        value="expand"
                      />
                      <Button
                        icon="fa-minimize"
                        onClick={handleCollapseAll}
                        tooltip="Collapse All"
                        value="collapse"
                      />
                    </ButtonGroup>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </StyledHeader>
    </div>
  );
};

const mapStateToProps = (state: {
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(Header);
