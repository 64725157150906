import PropTypes from "prop-types";

import gridSpacing from "../../../utils/gridSpacing";

export const marginPropTypes = {
  m: PropTypes.number,
  mx: PropTypes.number,
  my: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  mt: PropTypes.number,
  mb: PropTypes.number,
};

export const getMargins = ({ m, mx, my, mt, mb, mr, ml }) => {
  return {
    margin: gridSpacing[m],
    marginLeft: typeof ml !== "undefined" ? gridSpacing[ml] : gridSpacing[mx],
    marginRight: typeof mr !== "undefined" ? gridSpacing[mr] : gridSpacing[mx],
    marginTop: typeof mt !== "undefined" ? gridSpacing[mt] : gridSpacing[my],
    marginBottom: typeof mb !== "undefined" ? gridSpacing[mb] : gridSpacing[my],
  };
};
