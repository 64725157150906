import React, { useState } from "react";

import { Toast } from "@commonComponents";
import { Button } from "@commonComponents";
import axios from "axios";
import moment from "moment";
import queryString from "query-string";
import { connect } from "react-redux";
import { styled } from "styled-components";

import { gridSpacing } from "@utils";
import { colorTheme, makeHumanReadable } from "@utils";

import type { Filter } from "~/src/types";

const FilterItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${gridSpacing[3]}px ${gridSpacing[4]}px;
  border: 1px solid ${colorTheme("neutralL4")};
  border-radius: 6px;
  margin-top: ${gridSpacing[3]}px;
`;

const FilterItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoText = styled.div`
  color: ${colorTheme("neutralL2")};
  font-size: 14px;
`;

const QueryPill = styled.div`
  display: flex;
  align-items: center;
  gap: ${gridSpacing[1]}px;
  padding: ${gridSpacing[1]}px ${gridSpacing[3]}px;
  border-radius: 30px;
  border: 1px solid ${colorTheme("primaryL1")};
  background: ${colorTheme("primaryL4")};
  cursor: default;

  p {
    font-size: 14px;
  }

  p:last-child {
    color: ${colorTheme("primary")};
  }
`;

const FilterItem = ({
  openSavedFilter,
  removeFilter,
  name,
  created_by_name,
  created_by_username,
  modified,
  uuid,
  description,
  query,
  username,
}: Filter & {
  openSavedFilter: () => void;
  removeFilter: () => void;
  username: string;
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [deletingFilter, setDeletingFilter] = useState<boolean>(false);

  const queryObject = queryString.parse(query);

  return (
    <FilterItemWrapper>
      <FilterItemContainer>
        <b
          style={{ cursor: "pointer", color: colorTheme("primary") }}
          onClick={() => setOpened(!opened)}
        >
          <i
            className={`fa-solid fa-chevron-${opened ? "down" : "right"}`}
            style={{ marginRight: gridSpacing[2], width: 15 }}
          />
          {name}
        </b>

        <div
          style={{ display: "flex", alignItems: "center", gap: gridSpacing[3] }}
        >
          <InfoText>Created by: {created_by_name}</InfoText>
          <InfoText>Saved on: {moment(modified).format("MM/DD/YYYY")}</InfoText>
          {deletingFilter ? (
            <Button small disabled text="Deleting..." />
          ) : (
            <>
              <Button
                small
                style={{ padding: 0, minWidth: 60 }}
                text="Apply"
                type="secondary"
                onClick={openSavedFilter}
              />
              {username === created_by_username && (
                <Button
                  small
                  type="secondary"
                  icon="fa-trash"
                  onClick={async () => {
                    setDeletingFilter(true);

                    await axios
                      .delete(`search/filters/${uuid}`)
                      .then(() => {
                        // Timeout so user can see the "Deleting..." text
                        setTimeout(() => {
                          removeFilter();
                          setDeletingFilter(false);
                        }, 300);
                      })
                      .catch((err) => {
                        console.error(err);
                        setDeletingFilter(false);
                        Toast({
                          type: "error",
                          message: "Something went wrong deleting the filter",
                        });
                      });
                  }}
                />
              )}
            </>
          )}
        </div>
      </FilterItemContainer>
      {opened && (
        <div
          style={{
            display: "flex",
            gap: gridSpacing[2],
            flexWrap: "wrap",
            marginTop: gridSpacing[3],
            marginBottom: gridSpacing[3],
          }}
        >
          {Object.keys(queryObject).map((key, i) => {
            const splitKey = key.split(".");
            const keyName = splitKey[splitKey.length - 1];

            let values = queryObject[key];
            if (Array.isArray(values)) {
              values = values
                .map((value) => makeHumanReadable(value))
                .join(", ");
            }

            return (
              <QueryPill key={i}>
                <p>{makeHumanReadable(keyName)}</p>
                <p style={{ color: colorTheme("neutralL3") }}>|</p>
                <p>{values}</p>
              </QueryPill>
            );
          })}
        </div>
      )}
      <InfoText
        style={{ color: colorTheme("neutral"), marginTop: gridSpacing[1] }}
      >
        {description}
      </InfoText>
    </FilterItemWrapper>
  );
};

const mapStateToProps = (state: { main: { user: { username: string } } }) => {
  return {
    username: state.main.user.username,
  };
};

export default connect(mapStateToProps)(FilterItem);
