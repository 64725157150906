import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import { Table } from "@commonComponents";
import moment from "moment";

import { gridSpacing } from "@utils";

import {
  averagePatientCountQuery,
  averageVisitCountQuery,
  averageVisitPerProviderQuery,
  visitCountByCategoryQuery,
  visitCountByTypeQuery,
  visitCountTrendQuery,
  summaryFilterDimensions,
  visitCountByTypeQuarterQuery,
  visitCountByCategoryQuarterQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import {
  getDataByAppointmentTypeOrCategory,
  getDrilldownAppointmentByQuarter,
  getColumnsForDrillDownTable,
  getDataForDrillDownTable,
} from "../Common/appointmentCommon";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const AppointmentTrendSummary = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: summaryFilterDimensions,
  });

  const {
    resultSet: averageVisitCountResult,
    error: averageVisitCountError,
    isLoading: averageVisitCountLoading,
  } = useCubeApiQuery(averageVisitCountQuery(filters, filterOrder));

  const {
    resultSet: averagePatientCountResult,
    error: averagePatientCountError,
    isLoading: averagePatientCountLoading,
  } = useCubeApiQuery(averagePatientCountQuery(filters, filterOrder));

  const {
    resultSet: averageVisitPerProviderResult,
    error: averageVisitPerProviderError,
    isLoading: averageVisitPerProviderLoading,
  } = useCubeApiQuery(averageVisitPerProviderQuery(filters, filterOrder));

  const {
    resultSet: visitCountByTypeResult,
    error: visitCountByTypeError,
    isLoading: visitCountByTypeLoading,
  } = useCubeApiQuery(visitCountByTypeQuery(filters, filterOrder));

  const {
    resultSet: visitCountByTypeDrilldownResult,
    error: visitCountByTypeDrilldownError,
    isLoading: visitCountByTypeDrilldownLoading,
  } = useCubeApiQuery(visitCountByTypeQuarterQuery(filters, filterOrder));

  const {
    resultSet: visitCountByCategoryResult,
    error: visitCountByCategoryError,
    isLoading: visitCountByCategoryLoading,
  } = useCubeApiQuery(visitCountByCategoryQuery(filters, filterOrder));

  const {
    resultSet: visitCountByCategoryDrilldownResult,
    error: visitCountByCategoryDrilldownError,
    isLoading: visitCountByCategoryDrilldownLoading,
  } = useCubeApiQuery(visitCountByCategoryQuarterQuery(filters, filterOrder));

  const {
    resultSet: growthTrendResult,
    error: growthTrendError,
    isLoading: growthTrendLoading,
  } = useCubeApiQuery(visitCountTrendQuery(filters, filterOrder));

  const getGrowthLineTrend = () => {
    const data = growthTrendResult?.rawData() ?? [];
    const apptTypes = [
      ...new Set(
        data.map((obj) => obj["appointment_completed.appointment_category"]),
      ),
    ];

    const yearmo = [
      ...new Set(
        data
          .map((obj) => moment(obj["appointment_completed.appointment_date"]))
          .sort((a, b) => a.valueOf() - b.valueOf())
          .map((yearmo) => yearmo.format("MM/YYYY")),
      ),
    ];

    const returnData = apptTypes.map((apptType) => {
      const filteredData = data.filter(
        (obj) => obj["appointment_completed.appointment_category"] === apptType,
      );
      return {
        name: apptType,
        data: filteredData.map((obj) =>
          parseInt(obj["appointment_completed.total_appt_count"]),
        ),
      };
    });
    return {
      data: returnData,
      options: yearmo,
    };
  };

  const categoryDrillDownData = getDrilldownAppointmentByQuarter(
    visitCountByCategoryResult?.rawData().map((o) => {
      return {
        name: o["appointment_completed.appointment_category"],
        count: parseInt(o["appointment_completed.total_appt_count"]),
      };
    }),
    visitCountByCategoryDrilldownResult?.rawData().map((o) => {
      return {
        name: o["appointment_completed.appointment_category"],
        count: o["appointment_completed.appointment_count"],
        quarter: o["appointment_completed.appointment_date.quarter"],
      };
    }),
  );

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          loading={averageVisitCountLoading}
          error={averageVisitCountError && "Something went wrong"}
          toolTip="Average number of completed visits per day for the last 90 days"
          title="Average Visit Count"
          subTitle="Visits per day for the last 90 days"
          numerator={parseFloat(
            (
              parseInt(
                averageVisitCountResult?.rawData()[0][
                  "appointment_completed.avg_appointment_count_90_days"
                ] ?? "",
              ) / 90
            ).toFixed(2),
          )}
          unit="visits"
          type="metric"
        />
        <DataViz.NumberChart
          loading={averagePatientCountLoading}
          error={averagePatientCountError && "Something went wrong"}
          toolTip="Average number of patients seen per day for the last 90 days"
          title="Average Patient Count"
          subTitle="Patients per day for the last 90 days"
          numerator={parseFloat(
            (
              parseInt(
                averagePatientCountResult?.rawData()[0][
                  "appointment_completed.avg_patient_count_90_days"
                ] ?? "",
              ) / 90
            ).toFixed(2),
          )}
          type="metric"
          unit="patients"
        />
        <DataViz.NumberChart
          loading={averageVisitPerProviderLoading}
          error={averageVisitPerProviderError && "Something went wrong"}
          toolTip="Average number of completed visits per day per provider for the last 90 days"
          title="Average Visit Count per Provider"
          subTitle="Visits per provider per day for the last 90 days"
          numerator={parseFloat(
            (
              parseInt(
                averageVisitPerProviderResult?.rawData()[0][
                  "appointment_completed.total_appt_count_last_90_days"
                ] ?? "",
              ) /
              parseInt(
                averageVisitPerProviderResult?.rawData()[0][
                  "appointment_completed.total_provider_count"
                ] ?? "",
              ) /
              90
            ).toFixed(2),
          )}
          type="metric"
          unit="visits"
        />
      </DataViz.Row>

      <DataViz.Row>
        <DataViz.WaterfallChart
          title="Visit Count by Type"
          subtitle="Click on a visit type to see its breakdown over time"
          toolTip="Count of completed visits by visit types (total as of current date)"
          yAxisLabel="Visit Counts"
          data={getDataByAppointmentTypeOrCategory(
            visitCountByTypeResult?.rawData(),
          ).map((o) => {
            return { ...o, type: "column" };
          })}
          drillDown={getDrilldownAppointmentByQuarter(
            visitCountByTypeResult?.rawData().map((o) => {
              return {
                name: o["appointment_completed.appointment_type"],
                count: parseInt(o["appointment_completed.total_appt_count"]),
              };
            }),
            visitCountByTypeDrilldownResult?.rawData().map((o) => {
              return {
                name: o["appointment_completed.appointment_type"],
                count: o["appointment_completed.appointment_count"],
                quarter: o["appointment_completed.appointment_date.quarter"],
              };
            }),
          )}
          loading={visitCountByTypeLoading || visitCountByTypeDrilldownLoading}
          error={
            (visitCountByTypeError || visitCountByTypeDrilldownError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.WaterfallChart
          title="Visit Count by Category"
          subtitle="Click on a visit type to see its breakdown over time"
          toolTip="Count of completed visits by visit categories (total as of current date)"
          yAxisLabel="Visit Counts"
          data={getDataByAppointmentTypeOrCategory(
            visitCountByCategoryResult?.rawData(),
          ).map((o) => {
            return { ...o, type: "column" };
          })}
          drillDown={categoryDrillDownData}
          loading={
            visitCountByCategoryLoading || visitCountByCategoryDrilldownLoading
          }
          error={
            (visitCountByCategoryError || visitCountByCategoryDrilldownError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <Header
        title="Visit Count by Category"
        toolTip="Count of completed visits over time by visit categories (as of current date)"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={visitCountByCategoryDrilldownLoading}
        columns={getColumnsForDrillDownTable("category", categoryDrillDownData)}
        data={getDataForDrillDownTable(categoryDrillDownData)}
        style={{ marginBottom: gridSpacing[5] }}
        defaultSorted={[
          {
            id: "lastCol",
            desc: true,
          },
        ]}
        pageSize={10}
        exportAction={() => {
          setTableExport({
            data: getDataForDrillDownTable(categoryDrillDownData),
            columnMap: getColumnsForDrillDownTable(
              "category",
              categoryDrillDownData,
            ).map((column) => {
              return {
                csvColumn: column.Header,
                jsColumn: column.accessor,
              };
            }),
            exportName: "Visit Count by Category",
          });
        }}
      />
      <DataViz.Row>
        <DataViz.LineChart
          title="Visit Count Trend"
          subtitle="Click on a legend to hide the corresponding series from the chart"
          loading={growthTrendLoading}
          error={growthTrendError && "Something went wrong"}
          yAxisLabel="Total Members"
          toolTip="Analysis of monthly change in completed visit count "
          {...getGrowthLineTrend()}
        />
      </DataViz.Row>
    </>
  );
};

export default AppointmentTrendSummary;
