import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: ${({ $inlineActions }) =>
    $inlineActions ? "row-reverse" : "column"};
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const LeftActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;
const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
`;

function Filters({ children }) {
  return <LeftActions>{children}</LeftActions>;
}

function Actions({ inlineActions = true, style, children }) {
  let filters = null;
  let actions = [];

  if (Array.isArray(children)) {
    children.forEach((child) => {
      if (child?.type === Filters) {
        filters = child;
      } else if (child) {
        actions.push(child);
      }
    });
  } else if (children?.type === Filters) {
    filters = children;
  } else if (children) {
    actions = children;
  }

  return (
    <Container style={style} $inlineActions={inlineActions}>
      <RightActions>{actions}</RightActions>
      {filters}
    </Container>
  );
}

Filters.propTypes = {
  children: PropTypes.any,
};

Actions.propTypes = {
  inlineActions: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.any,
};

Actions.Filters = Filters;

export default Actions;
